import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"

import '../styles/Item.css';


class Item extends React.Component {

    state = { pickedItem: null }

    itemPicked = item => {
        this.props.itemPicked(item)
    }

    render() {
        const base_url = "https://www.menulutfen.com/images/item/"
        return (

            <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); if (!this.props.chevron) this.props.editItem(this.props.data); else this.itemPicked(this.props.data); }
            } style={{ borderRadius: 20, background: "rgba(255,255,255,.2)", margin: "2px 12px", border: "solid 2px rgba(0,0,0,.12)", display: "flex", flexDirection: "row", padding: "12px 12px", alignItems: "center" }}>
                
                    <div style={{ width: "20%", paddingTop: "20%", position: "relative" }}>

                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, }}>

                            <img alt="item" className="photo" src={base_url + this.props.data.item_photo} style={{ width: "100%", height: "100%", objectFit: "cover", background: "white", borderRadius: "6px" }} />

                        </div>

                    </div>

                <div style={{ marginLeft: "16px", display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>

                    <p style={{ fontSize: "18px", fontWeight: "bold", textShadow: "1px 1px 1px #aaa" }}>
                        {this.props.data.item_name}
                    </p>
                    <p style={{ height:"27px", fontSize: "12px", textShadow: "1px 1px 1px #aaa", textOverflow: "ellipsis", overflow: "hidden" }}>
                        {this.props.data.item_description}
                    </p>

                    <p style={{ fontSize: "20px", color: "#f80", fontWeight: "bold", textShadow: "1px 1px 1px #aaa" }}>
                        {this.props.data.item_currency + " " + this.props.data.item_price.toFixed(2)}
                    </p>

                </div>


                {
                    this.props.chevron ?
                        <div style={{ padding: "12px", height: "100%", display: "flex", alignItems: "center", color: "#888", fontWeight: "bold" }}>{">"}</div>
                        :
                        <img alt="remove" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.removeItem(this.props.data) }} src={require("../assets/remove.png")} style={{ width: "32px", height: "32px", padding: "6px" }} />
                }

            </div>
        )
    }

}

export default Item;