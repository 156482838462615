import React from "react"
//import '../styles/Item.css';


class Loading extends React.Component {



    render() {

        return (
            <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(255,255,255,.6)", zIndex:10000 }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
                    <div style={{ boxShadow: "0 0 10px 1px black", border: "solid 8px #bbb", width: "160px", height: "160px", display: "flex", justifyContent: "center", alignItems: "center", background: "white", borderRadius: "100px", overflow: "hidden" }}>
                        <img alt="animation" src={require("../assets/menu_animation.gif")} style={{ width: "240px" }} />
                    </div>
                </div>
            </div>
        )
    }

}

export default Loading;