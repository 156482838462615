import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import PanelHeader from "./PanelHeader"

//import '../styles/Item.css';


class Panel extends React.Component {

    state = {}

    render() {

        return (
            <div style={{ display: "flex", flex: 1 }}>
                <PanelHeader logout={this.props.logout} data={this.props.data} />
            </div>
        )
    }

}

export default Panel;