import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import html2canvas from "html2canvas";

class Links extends React.Component {

    state = { url: this.props.url || "" }

    constructor(props) {
        super(props)
        this.printRef = React.createRef()
    }

    handleDownloadImage = async () => {
        const element = this.printRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
            link.href = data;
            link.download = 'image.jpg';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
    };

    render() {


        return (
            <div className="qrcode__container" style={{ display: "flex", justifyContent: 'center', flexDirection: "column" }}>
                <a style={{ marginBottom:"20px", textAlign:"center" }} target={"_blank"} href={"https://www.menulutfen.com/menu?id=" + this.props.data.company.company_url}>menulutfen.com/menu?id={this.props.data.company.company_url}</a>
                <div ref={this.printRef} style={{ display: "flex", justifyContent: 'center', alignItems: "center", padding: "20px", borderRadius: "20px", border: "solid 8px black", marginBottom:"20px" }}>
                    <QRCodeCanvas
                        id="qrCode"
                        value={"https://www.menulutfen.com/menu?id=" + this.props.data.company.company_url}
                        bgColor={"white"}
                        level={"H"}
                        size={200}
                    />
                </div>
                <button className="btn btn-primary" type="button" onClick={this.handleDownloadImage}>
                    Fotoğraf olarak indir
                </button>

            </div>
        );
    }
};

export default Links;