import React from "react"
import { SwatchesPicker } from 'react-color';
import axios from "axios";
import '../styles/OrganizationEdit.css';
import Loading from "./Loading";


class OrganizationEdit extends React.Component {
    constructor(props) {
        super()
        
        this.state = {
            requesting: false,
            company_name: props.data.company.company_name || null,
            company_id: props.data.company.company_id,
            coverPhoto: props.data.company.company_cover_photo || "",
            logoPhoto: props.data.company.company_logo || "",
            company_color: props.data.company.company_color || "black"
        }
    }



    coverUpload = (event) => {

        this.setState({ requesting: true })

        const fd = new FormData();
        fd.append('image', event.target.files[0], event.target.files[0].name);
        fd.append('company_id', this.state.company_id);
        axios.post('https://www.menulutfen.com/services/cover_photo_upload.php?t=' + new Date().getTime(), fd
        ).then(res => {
            if (res.data.upload) {
                this.setState({ coverPhoto: res.data.upload })
            }
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
        }
        );

    }
    logoUpload = (event) => {
        this.setState({ requesting: true })
        const fd = new FormData();
        fd.append('image', event.target.files[0], event.target.files[0].name);
        fd.append('company_id', this.state.company_id);
        axios.post('https://www.menulutfen.com/services/logo_photo_upload.php?t=' + new Date().getTime(), fd
        ).then(res => {
            if (res.data.upload) {
                this.setState({ logoPhoto: res.data.upload })
            }
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
        }
        );

    }

    handleChange = (color, event) => {
        this.setState({ company_color: color.hex })

    }

    save = () => {

        this.setState({ requesting: true })
        const { company_id, company_name, logoPhoto, coverPhoto, company_color } = this.state
        axios.post('https://www.menulutfen.com/services/edit_organization.php?t=' + new Date().getTime(),
            {
                company_id,
                company_name,
                logoPhoto,
                coverPhoto,
                company_color
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).then((response) => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)

            if (response) {
                alert("Kaydedildi")
            }

        }).catch((error) => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
            console.log(error)
        })
    }


    render() {

        return (
            <div style={{ width: "80%", display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", }}>

                {this.state.requesting && <Loading />}

                <p style={{ fontFamily: "Montserrat", fontWeight: 500, width: "100%" }}>Kapak Fotoğrafı:</p>
                {this.state.coverPhoto ?
                    <img alt="coverPhoto" src={"https://www.menulutfen.com/photos/organizations/covers/" + this.state.coverPhoto} style={{ marginTop: "10px", objectFit: "cover", width: "200px", height: "400px", borderRadius: "32px" }} />
                    :
                    <img alt="coverPhoto" src={require("../assets/restaurant-menu.png")} style={{ marginTop: "10px", objectFit: "cover", width: "200px", height: "400px", borderRadius: "32px" }} />
                }

                <div style={{ paddingLeft: "12px", paddingBottom: "12px", borderBottom: "solid 1px #ddd", marginTop: "12px", width: "100%", display: "flex", justifyContent: 'center', alignItems: "center", }}>
                    <label style={{ padding: "4px 8px", border: "solid 2px #08f", color: "#08f", borderRadius: "8px" }} for="cover_upload">Fotoğraf seçin</label>
                    <input id="cover_upload" type="file" accept="image/*" onChange={this.coverUpload} style={{ display: "none" }} />
                </div>


                <p style={{ marginTop: "32px", fontFamily: "Montserrat", fontWeight: 500, width: "100%" }}>Logo Fotoğrafı:</p>

                {this.state.logoPhoto ?
                    <img alt="logoPhoto" src={"https://www.menulutfen.com/photos/organizations/logos/" + this.state.logoPhoto} style={{ marginTop: "10px", objectFit: "cover", maxWidth: "200px", width:"auto", height: "40px", borderRadius: "2px" }} />
                    :
                    <img alt="logoPhoto" src={require("../assets/restaurant-menu.png")} style={{ marginTop: "10px", objectFit: "cover", maxWidth: "200px", width:"auto", height: "40px", borderRadius: "2px" }} />
                }

                <div style={{ paddingLeft: "12px", paddingBottom: "12px", borderBottom: "solid 1px #ddd", marginTop: "12px", width: "100%", display: "flex", justifyContent: 'center', alignItems: "center", }}>
                    <label style={{ padding: "4px 8px", border: "solid 2px #08f", color: "#08f", borderRadius: "8px" }} for="logo_upload">Fotoğraf seçin</label>
                    <input id="logo_upload" type="file" accept="image/*" onChange={this.logoUpload} style={{display:"none"}}/>
                </div>


                <p style={{ marginTop: "32px", fontFamily: "Montserrat", fontWeight: 500, width: "100%" }}>İşletme Adı:</p>
                <input placeholder="Menü Lütfen" onChange={e => this.setState({ company_name: e.target.value })} value={this.state.company_name} style={{ width: "100%" }} />

                <p style={{ display: "flex", alignItems: "center", marginTop: "32px", fontFamily: "Montserrat", fontWeight: 500, width: "100%", marginBottom: "10px" }}>Arkaplan Rengi: <div style={{ marginLeft: "10px", width: "24px", height: "16px", background: this.state.company_color }} ></div> </p>
                <input placeholder="#000000" onChange={e => this.setState({ company_color: e.target.value })} value={this.state.company_color} style={{ width: "100%" }} />
                <SwatchesPicker width={"100%"} onChange={this.handleChange} />

                <button onClick={this.save} className="btn btn-success" style={{ marginTop: "12px", fontFamily: "Montserrat", fontWeight: 500 }}>Kaydet</button>
            </div>
        )
    }

}

export default OrganizationEdit;