import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Category from "./Category"


class Categories extends React.Component {

    categories = [
        {
            category: "Test", photo: "https://menucebimde.com/_imgs/2W15Q1-U181817-UVJKJ.JPG",
            data: [
                { title: "Healthy Balls", description: "Vegan, glutensiz, rafine şekersiz", price: "10.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181811-BNVQ9.JPG" },
                { title: "Balkabaklı Kurabiye", description: "Vegan", price: "20.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181812-RF3SG.JPG" },
                { title: "Limonlu Kurabiye", description: "Vegan, glutensiz, rafine şekersiz", price: "16.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181813-A9NUT.JPG" },
                { title: "Makaron", description: "", price: "15.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181814-GRWJ1.JPG" },
                { title: "Chocolate Chip Cookie", description: "", price: "40.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181816-K7GL1.JPG" },
                { title: "Glutensiz Brownie", description: "", price: "40.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181817-UVJKJ.JPG" },
                { title: "Simit", description: "Sıcak servis edilir.", price: "40.00", photo: "https://cdn.yemek.com/mncrop/940/625/uploads/2017/11/citir-citir-ev-yapimi-sokak-simidi-tarifi.jpg" },
            ]
        },
        {
            category: "Tuzlu", photo: "https://menucebimde.com/_imgs/2W15Q1-U181816-K7GL1.JPG",
            data: [
                { title: "Healthy Balls", description: "Vegan, glutensiz, rafine şekersiz", price: "10.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181811-BNVQ9.JPG" },
                { title: "Balkabaklı Kurabiye", description: "Vegan", price: "20.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181812-RF3SG.JPG" },
                { title: "Limonlu Kurabiye", description: "Vegan, glutensiz, rafine şekersiz", price: "16.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181813-A9NUT.JPG" },
                { title: "Makaron", description: "", price: "15.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181814-GRWJ1.JPG" },
                { title: "Chocolate Chip Cookie", description: "", price: "40.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181816-K7GL1.JPG" },
                { title: "Glutensiz Brownie", description: "", price: "40.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181817-UVJKJ.JPG" },
                { title: "Simit", description: "Sıcak servis edilir.", price: "40.00", photo: "https://cdn.yemek.com/mncrop/940/625/uploads/2017/11/citir-citir-ev-yapimi-sokak-simidi-tarifi.jpg" },
            ]
        },
        {
            category: "Yemek", photo: "https://menucebimde.com/_imgs/2W15Q1-U181814-GRWJ1.JPG",
            data: [
                { title: "Healthy Balls", description: "Vegan, glutensiz, rafine şekersiz", price: "10.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181811-BNVQ9.JPG" },
                { title: "Balkabaklı Kurabiye", description: "Vegan", price: "20.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181812-RF3SG.JPG" },
                { title: "Limonlu Kurabiye", description: "Vegan, glutensiz, rafine şekersiz", price: "16.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181813-A9NUT.JPG" },
                { title: "Makaron", description: "", price: "15.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181814-GRWJ1.JPG" },
                { title: "Chocolate Chip Cookie", description: "", price: "40.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181816-K7GL1.JPG" },
                { title: "Glutensiz Brownie", description: "", price: "40.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181817-UVJKJ.JPG" },
                { title: "Simit", description: "Sıcak servis edilir.", price: "40.00", photo: "https://cdn.yemek.com/mncrop/940/625/uploads/2017/11/citir-citir-ev-yapimi-sokak-simidi-tarifi.jpg" },
            ]
        },
        {
            category: "İçecek", photo: "https://menucebimde.com/_imgs/2W15Q1-U181812-RF3SG.JPG",
            data: [
                { title: "Healthy Balls", description: "Vegan, glutensiz, rafine şekersiz", price: "10.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181811-BNVQ9.JPG" },
                { title: "Balkabaklı Kurabiye", description: "Vegan", price: "20.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181812-RF3SG.JPG" },
                { title: "Limonlu Kurabiye", description: "Vegan, glutensiz, rafine şekersiz", price: "16.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181813-A9NUT.JPG" },
                { title: "Makaron", description: "", price: "15.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181814-GRWJ1.JPG" },
                { title: "Chocolate Chip Cookie", description: "", price: "40.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181816-K7GL1.JPG" },
                { title: "Glutensiz Brownie", description: "", price: "40.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181817-UVJKJ.JPG" },
                { title: "Simit", description: "Sıcak servis edilir.", price: "40.00", photo: "https://cdn.yemek.com/mncrop/940/625/uploads/2017/11/citir-citir-ev-yapimi-sokak-simidi-tarifi.jpg" },
            ]
        },
        {
            category: "Tatlı", photo: "https://menucebimde.com/_imgs/2W15Q1-U181811-BNVQ9.JPG",
            data: [
                { title: "Healthy Balls", description: "Vegan, glutensiz, rafine şekersiz", price: "10.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181811-BNVQ9.JPG" },
                { title: "Balkabaklı Kurabiye", description: "Vegan", price: "20.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181812-RF3SG.JPG" },
                { title: "Limonlu Kurabiye", description: "Vegan, glutensiz, rafine şekersiz", price: "16.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181813-A9NUT.JPG" },
                { title: "Makaron", description: "", price: "15.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181814-GRWJ1.JPG" },
                { title: "Chocolate Chip Cookie", description: "", price: "40.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181816-K7GL1.JPG" },
                { title: "Glutensiz Brownie", description: "", price: "40.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181817-UVJKJ.JPG" },
                { title: "Simit", description: "Sıcak servis edilir.", price: "40.00", photo: "https://cdn.yemek.com/mncrop/940/625/uploads/2017/11/citir-citir-ev-yapimi-sokak-simidi-tarifi.jpg" },
            ]
        },
        {
            category: "Tuzlu", photo: "https://menucebimde.com/_imgs/2W15Q1-U181813-A9NUT.JPG",
            data: [
                { title: "Healthy Balls", description: "Vegan, glutensiz, rafine şekersiz", price: "10.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181811-BNVQ9.JPG" },
                { title: "Balkabaklı Kurabiye", description: "Vegan", price: "20.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181812-RF3SG.JPG" },
                { title: "Limonlu Kurabiye", description: "Vegan, glutensiz, rafine şekersiz", price: "16.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181813-A9NUT.JPG" },
                { title: "Makaron", description: "", price: "15.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181814-GRWJ1.JPG" },
                { title: "Chocolate Chip Cookie", description: "", price: "40.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181816-K7GL1.JPG" },
                { title: "Glutensiz Brownie", description: "", price: "40.00", photo: "https://menucebimde.com/_imgs/2W15Q1-U181817-UVJKJ.JPG" },
                { title: "Simit", description: "Sıcak servis edilir.", price: "40.00", photo: "https://cdn.yemek.com/mncrop/940/625/uploads/2017/11/citir-citir-ev-yapimi-sokak-simidi-tarifi.jpg" },
            ]
        }
    ]

    pickedCategory = data => {
        if (this.props.clickable)
            this.props.pickedCategory(data)
    }

    render() {

        return (

            <div className="col-12 row" style={{ padding: "12px" }}>

                {false && <div style={{ width: "100%", textAlign: "center" }}>{() => { setTimeout(() => { if (!this.props.data.length) return "Eklenmiş kategori bulunmamakta." }, 1000) }}</div>}

                {this.props.data.map((item, index) =>
                    <Category pickedCategory={this.pickedCategory} edit={this.props.edit} clickable={this.props.clickable} key={index} data={item} editCategory={this.props.editCategory} removeCategory={this.props.removeCategory} />
                )}

            </div>

        )

    }

}

export default Categories;