import axios from "axios";
import Admin from "./components/Admin";
import Header from "./components/Header"
import Items from "./components/Items"
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"
import './App.css';
import Categories from "./components/Categories";
import { Route } from "react-router-dom";
import Search from "./components/Search";
import Landing from "./components/Landing";
import Loading from "./components/Loading";
import View from "./components/View";

class App extends React.Component {

  state = {
    pickedCategory: null, data: [], company: null, searchVisible: false,
    pickedItem: null,
    containerOpacity: 1,
    initial: true, tapScale: "scale(1)"
  }

  componentDidMount() {

    this.setState({ tapScale: "scale(1)" })
    setTimeout(() => {
      this.setState({ tapScale: "scale(.7)" })
    }, 300)
    setTimeout(() => {
      this.setState({ tapScale: "scale(1)" })
      this.animateTap()
    }, 600)

    const id = window.location.search.split("=")[1]
    if (id)
      this.fetchData(id)
  }

  animateTap = () => {
    setInterval(() => {
      this.setState({ tapScale: "scale(1)" })
      setTimeout(() => {
        this.setState({ tapScale: "scale(.7)" })
      }, 300)
      setTimeout(() => {
        this.setState({ tapScale: "scale(1)" })
      }, 600)

    }, 3000)
  }

  pickedCategory = data => {
    this.setState({ containerOpacity: 0 })
    setTimeout(() => {
      this.setState({ pickedCategory: data, containerOpacity: 1 })
    }, 300)

  }


  itemPicked = item => {
    this.setState({ containerOpacity: 0 })
    setTimeout(() => {
      this.setState({ pickedItem: item, containerOpacity: 1 })
    }, 300)
  }

  search = () => {
    this.setState({ containerOpacity: 0 })
    setTimeout(() => {
      this.setState({ searchVisible: true, containerOpacity: 1 })
    }, 300)
  }

  back = () => {
    this.setState({ containerOpacity: 0 })
    setTimeout(() => {
      if (this.state.pickedItem) { this.setState({ pickedItem: null, containerOpacity: 1 }); return; }
      if (this.state.searchVisible) { this.setState({ searchVisible: false, containerOpacity: 1 }); return; }
      if (this.state.pickedCategory) { this.setState({ pickedCategory: null, containerOpacity: 1 }) }
    }, 300)

  }


  fetchData = (id) => {
    this.setState({ requesting: true })
    axios.post('https://www.menulutfen.com/services/fetch_data.php',
      {
        company_url: id
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    ).then((response) => {
      setTimeout(() => {
        this.setState({ requesting: false })
      }, 300)
      if (response.data) {
        this.setState({ data: this.shapeResponse(response.data) })
      }
    }).catch(e => {
      console.log(e)
      setTimeout(() => {
        this.setState({ requesting: false })
      }, 300)

    })
  }

  shapeResponse = data => {
    let temp = []

    if (data.category_language.length) {

      for (let i = 0; i < data.category_language.length; i++) {
        temp.push({
          category: data.category_name[i],
          category_photo: data.category_photo[i],
          category_id: data.category_id[i],
          items: []
        })
      }

      let shapedData = { categories: {} }
      temp.map(item => {
        if (!shapedData.categories[item.category]) {
          shapedData.categories[item.category] = item
        }
      })

      let items = []
      for (let i = 0; i < data.category_language.length; i++) {
        items.push({
          item_name: data.item_name[i],
          item_description: data.item_description[i],
          item_photo: data.item_photo[i],
          item_price: data.item_price[i],
          item_currency: data.item_currency[i],
          item_category: data.category_name[i],
        })
      }

      items.map(item => {
        shapedData.categories[item.item_category].items.push(item)
      })

      let shapedArray = []
      Object.keys(shapedData.categories).forEach(item => {
        shapedArray.push(shapedData.categories[item])
      })


      this.setState({
        company: {
          company_id: data.company_id[0],
          company_name: data.company_name[0],
          company_logo: data.company_logo[0],
          company_cover_photo: data.company_cover_photo[0],
          company_color: data.company_color[0],
          company_theme: data.company_theme[0]
        }
      })

      return shapedArray


    }
    else {

    }

  }


  render() {

    if (this.state.company && this.state.company.company_color)
      document.querySelector('meta[name="theme-color"]').setAttribute("content", this.state.company.company_color);
    if (this.state.company && this.state.company.company_name) {
      document.querySelector('meta[name="description"]').setAttribute("content", this.state.company.company_name + " | menulutfen.com");
      document.getElementById("title").innerHTML = this.state.company.company_name + " | menulutfen.com"
    }

    const path = require("path");
    const fs = require("fs");

    const loadHTML = async () => {
      const filePath = path.resolve(__dirname, "../");
      const html = await fs.readFile(filePath, 'utf8');
      return html;
    }

    return (
      <div style={{ height: "100%", }}>
        {this.state.company && this.state.company["company_cover_photo"] &&
          <img style={{ transition: "all .2s ease-out", position: "absolute", top: 50, height:"calc(100% - 50px)", left: 0, width:"100%", objectFit: "cover", filter: !this.state.initial ? "blur(10px) brightness(.8)" : "unset", opacity: !this.state.initial ? .3 : 1, zIndex: -1 }} src={"https://menulutfen.com/photos/organizations/covers/" + this.state.company["company_cover_photo"]} />
        }
        <meta name="viewport" content="width=device-width, maximum-scale=1.0, minimum-scale=0.75, user-scalable=0" />
        <div style={{ margin: "auto" }}>




          <Route exact path="/">
            <Landing />
          </Route>

          <div style={{ maxWidth: 800, margin: "auto" }}>
            {this.state.requesting &&
              <Loading />
            }



            <Route exact path="/menu">
              {false && this.state.company && this.state.company.company_color &&
                <div style={{ fontSize: "12px", fontWeight: 500, boxShadow: "0 0 4px 1px #888", borderTop: "solid 1px white", position: "fixed", bottom: 0, right: "0px", left: "0px", background: this.state.company.company_color || "black", color: "white", padding: "6px 8px 6px 8px", textAlign: "end", borderRadius: "8px 8px 0 0 " }}>Crafted with <span role="img" style={{ color: "red" }}>♥️</span> by umtpz.</div>
              }

              {
                this.state.pickedItem &&
                <div style={{ transition: "all .2s ease-out", display: "flex", flex: 1, opacity: this.state.containerOpacity }}>
                  <View data={this.state.pickedItem} />
                </div>
              }

              {this.state.company &&
                <Header initial={this.state.initial} search={this.search} back={this.back} company={this.state.company} showBack={this.state.pickedCategory || this.state.searchVisible ? true : false} />
              }
              {!this.state.initial && !this.state.pickedItem &&
                <div style={{ transition: "all .2s ease-out", display: "flex", flex: 1, opacity: this.state.containerOpacity }}>
                  {this.state.searchVisible ?
                    <div style={{ display: "flex", flex: 1, paddingTop: "72px", justifyContent: "center" }}>
                      <Search itemPicked={this.itemPicked} data={this.state.data} />
                    </div>
                    :
                    <div style={{ display: "flex", flex: 1, paddingTop: "72px", justifyContent: "center", }}>
                      {!this.state.pickedCategory ?
                        <Categories clickable={true} pickedCategory={this.pickedCategory} data={this.state.data} />
                        :
                        <Items itemPicked={this.itemPicked} chevron={true} data={this.state.pickedCategory} />
                      }
                    </div>
                  }
                </div>

              }

              {this.state.initial &&
                <div onClick={() => this.setState({ initial: false })} style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(255,255,255,.2)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ transition: "all .2s ease-out", transform: this.state.tapScale }}><img src={require("./assets/double-tap.png")} style={{ marginTop:60, width: 100, opacity: .8, }} /></div>
                </div>

              }


            </Route>
            <Route exact path="/admin">
              <Admin />
            </Route>

          </div>


        </div>

      </div>
    );
  }
}

export default App;
