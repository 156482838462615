import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Login from "./Login";
import Panel from "./Panel";

//import '../styles/Item.css';


class Admin extends React.Component {

    state = { data: null }

    setData = data => {
        this.setState({ data })
    }

    logout = () => {
        if (window.confirm("Çıkış yapmak istediğinizden emin misiniz?") === true) {
            this.setState({data: null})
        }
        
    }

    render() {

        return (
            <div style={{}}>
                {this.state.data ?
                    <Panel logout={this.logout} data={this.state.data}/>
                    :
                    <Login setData={this.setData} />
                }

            </div>
        )
    }

}

export default Admin;