import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Item from "./Item"



class Search extends React.Component {

    state = { search: "" }

    componentDidMount() {

    }

    render() {
        let items = []
        this.props.data.map(category => {
            if (category.items && category.items.length > 0)
                items = [...items, ...category.items]
        })
        items.sort(function (a, b) {
            if (a.item_name != null && b.item_name != null)
                return a.item_name.localeCompare(b.item_name)
        });
        return (
            <div style={{ display: "flex", flex: 1, flexDirection: "column", padding: "12px" }}>

                <input onChange={e => this.setState({ search: e.target.value })} value={this.state.search} placeholder={"Ara"} style={{ width: "calc(100% - 48px)", margin: "0 24px 12px 24px", padding: "6px", borderRadius: "6px", border: "solid 2px #ddd" }} />

                {
                    items.map((item, index) => {
                        if (item.item_name != null && (item.item_name.toLowerCase()).indexOf(this.state.search.toLowerCase()) > -1)
                            return <Item itemPicked={this.props.itemPicked} editItem={() => { }} removeItem={() => { }} chevron={true} key={index} data={item} />
                    })
                }

            </div>
        )
    }

}

export default Search;