import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"



class View extends React.Component {


    componentDidMount() {

    }

    render() {
        const base_url = "https://www.menulutfen.com/images/item/"

        return (
            <div style={{ display: "flex", flex: 1, flexDirection: "column", padding: "12px", paddingTop: "100px", alignItems: "center", position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1,}}>
                <div style={{width:"100%", maxWidth:"600px", marginTop:"32px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <div style={{ width: "75%", paddingTop: "75%", position: "relative" }}>

                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, }}>

                            <img alt="item" className="photo" src={base_url + this.props.data.item_photo} style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "16px" }} />

                        </div>

                    </div>

                    <div style={{ marginTop: "62px", display: "flex", flex: 1, flexDirection: "column", width: "75%" }}>

                        <p style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "4px" }}>
                            {this.props.data.item_name}
                        </p>

                        <p style={{ fontSize: "16px", marginBottom: "5px"}}>
                            {this.props.data.item_description}
                        </p>

                        <p style={{ fontSize: "28px", color: "#f80", fontWeight: "bold" }}>
                            {this.props.data.item_currency + " " + this.props.data.item_price.toFixed(2)}
                        </p>

                    </div>
                </div>
            </div>
        )
    }

}

export default View;