import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"



class Header extends React.Component {
    constructor(props) {
        super()
        console.log(props)
        this.state = {
            company_id: props.company.company_id || null,
            company_name: props.company.company_name || null,
            company_logo: props.company.company_logo || null,
            company_cover_photo: props.company.company_cover_photo || null,
            company_color: props.company.company_color || null,
            company_theme: props.company.company_theme || null
        }
    }

    render() {

        return (


            <div style={{ boxShadow: "-12px 0 20px 1px black", borderBottom: "solid 1px white", padding: "0 16px", zIndex: 10, position: "fixed", top: -1, left: 0, right: 0, height: "60px", background: this.state.company_color || "black", borderRadius: "0 0 10px 10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                <img onClick={this.props.back} alt="back" src={require("../assets/back.png")} style={{ width: 24, height: 24, filter: "drop-shadow(0px 0px 1px #fff) invert(100%)", visibility: this.props.showBack ? "visible" : "hidden" }} />


                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    {this.state.company_logo ?
                        <img alt="logo" style={{ maxWidth: "200px", objectFit: "cover", width: "auto", height: "32px", borderRadius: "2px" }} src={"https://www.menulutfen.com/photos/organizations/logos/" + this.state.company_logo} />
                        :
                        <img alt="logo" style={{ maxWidth: "200px", objectFit: "cover", width: "auto", height: "32px", borderRadius: "2px" }} src={require("../assets/restaurant-menu.png")} />
                    }
                    {false && <img alt="logo_text" style={{ width: "132px", height: "32px", borderRadius: "25px" }} src={require("../assets/monstera_text.png")} />}
                </div>

                <div style={{ width: 28, height: 28 }}>
                    {!this.props.initial &&

                        <img onClick={this.props.search} alt="search" src={require("../assets/search.png")} style={{ filter: "drop-shadow(0px 0px 1px #fff) invert(100%)", width: 28, height: 28 }} />

                    }
                </div>
                <div style={{ position: "fixed", bottom: 0, left: 0, display: this.props.showBack ? "block" : "none", background: this.state.company_color || "black", padding: "16px", borderRadius: "8px 8px 8px 0" }}>
                    <img onClick={this.props.back} alt="back" src={require("../assets/back.png")} style={{ width: 24, height: 24, filter: "drop-shadow(0px 0px 1px #fff) invert(100%)", }} />
                </div>

            </div>
        )

    }

}

export default Header;