import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import OrganizationEdit from "./OrganizationEdit"
import CategoriesEdit from "./CategoriesEdit"
import ItemsEdit from "./ItemsEdit"
import Links from "./Links"



class PanelHeader extends React.Component {

    state = { selectedSection: 2 }

    changeSection = selectedSection => {
        this.setState({ selectedSection })
    }

    render() {

        return (

            <div style={{ paddingTop: "72px", paddingLeft: "72px", width: "100%" }}>

                <div style={{ boxShadow: "0 0 10px 1px black", borderBottom: "solid 1px white", padding: "0 16px", zIndex: 10, position: "fixed", top: 0, left: 0, right: 0, height: "60px", background: "black", borderRadius: "0 0 10px 10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>


                    <img onClick={this.props.back} alt="back" src={require("../assets/back.png")} style={{ width: 24, height: 24, filter: "invert(100%)", visibility: this.props.showBack ? "visible" : "hidden" }} />


                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <h1 style={{ color: "white", textAlign: "center", marginBottom: "12px", letterSpacing: "2px", margin: "auto" }}>𝕸𝖊𝖓𝖚̈ 𝕷𝖚̈𝖙𝖋𝖊𝖓</h1>
                    </div>


                    <img onClick={this.props.logout} alt="logout" src={require("../assets/logout.png")} style={{ width: 24, height: 24, filter: "invert(100%)" }} />

                </div>



                <div style={{ borderRadius: "0 20px 20px 0", position: "fixed", left: 0, top: "82px", bottom: "10px", padding: "20px 0", width: "60px", background: "black", zIndex: 100, boxShadow: "0 0 10px 1px black", border: "solid 1px white", borderLeft: "none", display: "flex", flexDirection: "column", alignItems: "center" }}>

                    <img alt="organizationEdit" src={require("../assets/buildings.png")}
                        onClick={this.changeSection.bind(this, 0)}
                        style={{ width: "30px", height: "30px", filter: "invert(100%)", margin: "10px 0", opacity: this.state.selectedSection === 0 ? 1 : .6 }}
                    />
                    <img alt="categoriesEdit" src={require("../assets/categories.png")}
                        onClick={this.changeSection.bind(this, 1)}
                        style={{ pointerEvents: "visible", width: "30px", height: "30px", filter: "invert(100%)", margin: "10px 0", opacity: this.state.selectedSection === 1 ? 1 : .6 }} />
                    <img alt="itemsEdit" src={require("../assets/menu.png")}
                        onClick={this.changeSection.bind(this, 2)}
                        style={{ pointerEvents: "visible", width: "30px", height: "30px", filter: "invert(100%)", margin: "10px 0", opacity: this.state.selectedSection === 2 ? 1 : .6 }} />
                    <img alt="qrEdit" src={require("../assets/qr.png")}
                        onClick={this.changeSection.bind(this, 3)}
                        style={{ pointerEvents: "visible", width: "30px", height: "30px", filter: "invert(100%)", margin: "10px 0", opacity: this.state.selectedSection === 3 ? 1 : .6 }} />
                    {false && <img alt="graphEdit" src={require("../assets/trend.png")}
                        onClick={this.changeSection.bind(this, 4)}
                        style={{ pointerEvents: "visible", width: "30px", height: "30px", filter: "invert(100%)", margin: "10px 0", opacity: this.state.selectedSection === 4 ? 1 : .6 }} />
                    }


                </div>


                <div style={{ width: "100%", display: "flex", flex:1, alignItems: 'center', flexDirection: "column", padding: "32px 0px", }}>
                    {this.state.selectedSection === 0 && <OrganizationEdit data={this.props.data} />}
                    {this.state.selectedSection === 1 && <CategoriesEdit data={this.props.data} edit={true}/>}
                    {this.state.selectedSection === 2 && <ItemsEdit data={this.props.data} />}
                    {this.state.selectedSection === 3 && <Links data={this.props.data} />}
                </div>

            </div>
        )

    }

}

export default PanelHeader;