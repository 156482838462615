import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Item from "./Item";

import '../styles/Items.css';


class Items extends React.Component {


    render() {
        let itemCount = 0

        return (

            <div className="container" style={{ height: "100%", width: "100%" }}>

                <div className="row" style={{ justifyContent: "center", margin: "0px 0px", paddingBottom: "60px"}}>
                    
                    {this.props.data.map((item, index) => {
                        if (item.item_name) {
                            itemCount++
                            return <Item itemPicked={this.props.itemPicked} editItem={this.props.editItem} removeItem={this.props.removeItem} chevron={this.props.chevron} key={index} data={item} />
                        }
                    }
                    )}

                </div>

                {itemCount === 0 &&
                    <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", height: "100%" }}>Bu kategoride ürün ekli değil.</div>
                }

            </div>

        )
    }

}

export default Items;