import React from "react"
import axios from "axios";
import Categories from "./Categories";
import Loading from "./Loading";


class CategoriesEdit extends React.Component {

    state = { company_name: null, addCategoryVisible: false, category_name: "", category_photo: "", category_language: "Türkçe", requesting: false }

    addCategory = () => {
        this.setState({ addCategoryVisible: !this.state.addCategoryVisible, editCategoryData: null, category_photo: null, category_name: null, category_id: null, })
    }

    categoryUpload = (event) => {

        this.setState({ requesting: true })

        const fd = new FormData();
        fd.append('image', event.target.files[0], event.target.files[0].name);
        fd.append('company_id', this.props.data.company.company_id);
        axios.post('https://www.menulutfen.com/services/category_photo_upload.php?t=' + new Date().getTime(), fd
        ).then(res => {
            if (res.data.upload) {
                this.setState({ category_photo: res.data.upload })
            }
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
        }
        );

    }

    add = () => {
        this.setState({ requesting: true })
        const { company_id } = this.props.data.company
        const { category_name, category_photo, category_language } = this.state

        if (!category_name || !category_photo) {
            alert("Lütfen ilgili alanları doldurun.")
            return
        }

        axios.post('https://www.menulutfen.com/services/add_category.php?t=' + new Date().getTime(),
            {
                company_id,
                category_language,
                category_name,
                category_photo
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).then((response) => {

            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)

            if (response.data.item_id) {
                this.props.data.data.push({
                    category: category_name,
                    category_id: response.data.item_id,
                    category_photo,
                    category_language,
                    items: []
                })

                this.props.data.data.sort(function (a, b) {
                    return a.category.localeCompare(b.category)
                });


                this.setState({ addCategoryVisible: false })
                this.forceUpdate()
            }
            else {
                alert("Hata")
            }

        }).catch((error) => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
            console.log(error)
        })
    }

    edit = () => {
        this.setState({ requesting: true })
        const { category_id, category_name, category_photo } = this.state

        if (!category_name || !category_photo) {
            alert("Lütfen ilgili alanları doldurun.")
            return
        }

        axios.post('https://www.menulutfen.com/services/edit_category.php?t=' + new Date().getTime(),
            {
                category_id,
                category_name,
                category_photo
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).then((response) => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
            if (response.data) {

                this.props.data.data.map(item => {
                    if (item.category_id === category_id) {
                        item.category = category_name
                        item.category_photo = category_photo
                    }
                })

                this.props.data.data.sort(function (a, b) {
                    return a.category.localeCompare(b.category)
                });
                this.setState({ editCategoryData: null, category_photo: null, category_name: null, category_id: null, addCategoryVisible: false })
                this.forceUpdate()
            }
            else {
                alert("Hata")
            }

        }).catch((error) => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
            console.log(error)
        })
    }

    editCategory = category => {
        this.setState({ editCategoryData: category, category_photo: category.category_photo, category_name: category.category, category_id: category.category_id, addCategoryVisible: true })
    }

    removeCategory = category => {
        if (this.props.data.data.length < 2) {
            alert("En az 1 kategori bulundurmalısınız. Lütfen 1 adet oluşturup, sonra silmeyi deneyin.")
            return
        }
        if (window.confirm("Bu kategori altında ürünler bulunmakta. Sildiğinizde, bu kategori altındaki ürünler de silinecek. Onaylıyor musunuz?") === true) {
            this.removeRequest(category, true)
        }
    }

    removeRequest = (category, w = false) => {
        this.setState({ requesting: true })

        axios.post('https://www.menulutfen.com/services/remove_category.php?t=' + new Date().getTime(),
            {
                category_id: category.category_id
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).then((response) => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
            if (response.data) {

                this.props.data.data.map((item, index) => {
                    if (item.category_id === category.category_id) {
                        this.props.data.data.splice(index, 1)
                    }
                })

                this.forceUpdate()
            }
            else {
                alert("Hata")
            }

        }).catch((error) => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
            console.log(error)
        })
    }

    render() {

        return (
            <div style={{ width: "80%", display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", }}>

                {this.state.requesting && <Loading />}


                {this.state.addCategoryVisible ?
                    <div style={{ width: "80%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", paddingTop: "32px" }}>

                        <div style={{ display: "flex", alignItems: "center", marginTop: "16px", fontFamily: "Montserrat", fontWeight: 500, width: "100%", marginBottom: "10px" }}>Kategori Adı: {!this.state.category_name && <span style={{ marginLeft: "8px", color: "red", fontWeight: "bold" }}>*</span>} </div>
                        <input onChange={e => this.setState({ category_name: e.target.value })} value={this.state.category_name} style={{ width: "100%" }} />

                        <p style={{ marginTop: "24px", fontFamily: "Montserrat", fontWeight: 500, width: "100%" }}>Kategori Fotoğrafı:{!this.state.category_photo && <span style={{ marginLeft: "8px", color: "red", fontWeight: "bold" }}>*</span>}</p>
                        {this.state.category_photo ?
                            <img alt="categoryPhoto" src={"https://www.menulutfen.com/images/category/" + this.state.category_photo} style={{ marginTop: "10px", objectFit: "cover", width: "200px", height: "200px", borderRadius: "32px" }} />
                            :
                            <img alt="categoryPhoto" src={require("../assets/restaurant-menu.png")} style={{ marginTop: "10px", objectFit: "cover", width: "200px", height: "200px", borderRadius: "32px" }} />
                        }
                        <div style={{ paddingBottom: "12px", borderBottom: "solid 1px #ddd", marginTop: "12px", width: "100%", display: "flex", justifyContent: 'center', alignItems: "center", }}>
                            <label style={{ padding: "4px 8px", border: "solid 2px #08f", color: "#08f", borderRadius: "8px" }} for="category_upload">Fotoğraf seçin</label>
                            <input id="category_upload" type="file" accept="image/*" onChange={this.categoryUpload} style={{ display: "none" }} />
                        </div>
                        {this.state.editCategoryData ?
                            <button onClick={this.edit} disabled={this.state.requesting} className="btn btn-primary" style={{ marginTop: "12px", fontFamily: "Montserrat", fontWeight: 500 }}>Düzenle</button>
                            :
                            <button onClick={this.add} disabled={this.state.requesting} className="btn btn-success" style={{ marginTop: "12px", fontFamily: "Montserrat", fontWeight: 500 }}>Yarat</button>
                        }

                    </div>
                    :
                    <Categories editCategory={this.editCategory} removeCategory={this.removeCategory} edit={true} clickable={false} data={this.props.data.data} />
                }

                <div onClick={this.addCategory}
                    style={{ position: "absolute", bottom: "10px", right: "10px", display: "flex", justifyContent: "center", alignItems: "center", color: "white", border: "solid 6px #ddd", background: this.state.addCategoryVisible ? "red" : "black", fontSize: "20px", lineHeight: "16px", fontWeight: "bold", width: "64px", height: "64px", borderRadius: "32px" }}
                > {this.state.addCategoryVisible ? "x" : "+"} </div>

            </div>
        )
    }

}

export default CategoriesEdit;