import React from "react"
import Items from "./Items";
import Loading from "./Loading";
import axios from "axios";


class ItemsEdit extends React.Component {

    state = { selectedCategory: 0, addItemVisible: false, item_name: "", item_price: "", item_description: "", item_photo: "", requesting: false }
    componentDidMount() {
    }

    addItem = () => {
        this.setState({ addItemVisible: !this.state.addItemVisible, item_id: null, item_name: null, item_description: null, item_price: null, item_photo: null, editItemData: null })
    }

    itemUpload = (event) => {
        this.setState({ requesting: true })

        const fd = new FormData();
        fd.append('image', event.target.files[0], event.target.files[0].name);
        fd.append('type', event.target.files[0].type);
        fd.append('ext', String(event.target.files[0].type).split("/")[1]);
        fd.append('company_id', this.props.data.company.company_id);
        axios.post('https://www.menulutfen.com/services/item_photo_upload.php?t=' + new Date().getTime(), fd
        ).then(res => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
            if (res.data.upload) {
                this.setState({ item_photo: res.data.upload })
            }
        })

    }

    add = () => {
        const { company_id } = this.props.data.company
        const { item_name, item_description, item_price, item_photo } = this.state

        if (!item_name || !item_description || !item_price || !item_photo) {
            alert("Lütfen ilgili alanları doldurun.")
            return
        }

        const category_id = this.props.data.data[this.state.selectedCategory].category_id

        this.setState({ requesting: true })
        axios.post('https://www.menulutfen.com/services/add_item.php?t=' + new Date().getTime(),
            {
                company_id,
                item_name,
                item_description,
                item_price: parseFloat(item_price),
                category_id,
                item_photo,
                item_currency: "₺",
                item_language: "Türkçe"
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).then((response) => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
            if (response) {
                this.props.data.data[this.state.selectedCategory].items.push({
                    item_id: response.data.item_id, item_name, item_description, item_photo: item_photo, item_price: parseFloat(item_price), item_currency: "₺", item_language: "Türkçe"
                })

                this.props.data.data[this.state.selectedCategory].items.sort(function (a, b) {
                    console.log(this.props.data.data[this.state.selectedCategory].items)
                    return a.item_name.localeCompare(b.item_name)
                });


                this.setState({ addItemVisible: false })
            }

        }).catch((error) => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
            console.log(error)
        })
    }

    editItem = item => {
        console.log(item)
        this.setState({ editItemData: item, addItemVisible: true, item_id: item.item_id, item_name: item.item_name, item_description: item.item_description, item_price: item.item_price, item_photo: item.item_photo })
    }

    edit = () => {

        this.setState({ requesting: true })
        const { item_id, item_name, item_description, item_price, item_photo } = this.state

        if (!item_name || !item_description || !item_price || !item_photo) {
            alert("Lütfen ilgili alanları doldurun.")
            return
        }


        const category_id = this.props.data.data[this.state.selectedCategory].category_id




        axios.post('https://www.menulutfen.com/services/edit_item.php?t=' + new Date().getTime(),
            {
                item_id,
                item_name,
                item_description,
                item_price: parseFloat(item_price),
                categories_id: category_id,
                item_photo
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).then((response) => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
            if (response.data) {

                console.log(this.state.editItemData.category_id)

                //if (category_id !== this.state.editItemData.category_id) 
                {
                    this.props.data.data.map((category, i) => {

                        if (category.category_id === this.state.editItemData.category_id) {
                            this.props.data.data[i].items.map((item, index) => {
                                if (this.state.editItemData.item_id === item.item_id) {
                                    this.props.data.data[i].items.splice(index, 1)
                                }
                            })
                        }
                        if (category.category_id === category_id) {
                            const temp = JSON.parse(JSON.stringify(this.state.editItemData))
                            console.log(temp)
                            temp.category_id = category_id
                            temp.item_name = item_name
                            temp.item_description = item_description
                            temp.item_photo = item_photo
                            this.props.data.data[i].items.push(temp)
                            this.props.data.data[i].items.sort(function (a, b) {
                                return a.item_name.localeCompare(b.item_name)
                            });
                        }
                    })
                }


                this.setState({ addItemVisible: false })
            }

        }).catch((error) => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
            console.log(error)
        })
    }

    removeItem = item => {
        if (window.confirm("Ürünü silinecek. Onaylıyor musunuz?") === true) {
            this.removeRequest(item.item_id)
        }
    }

    removeRequest = (item_id) => {
        this.setState({ requesting: true })

        axios.post('https://www.menulutfen.com/services/remove_item.php?t=' + new Date().getTime(),
            {
                item_id
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).then((response) => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)

            if (response.data) {

                this.props.data.data[this.state.selectedCategory].items.map((item, index) => {
                    if (item.item_id === item_id) {
                        this.props.data.data[this.state.selectedCategory].items.splice(index, 1)
                    }
                })

                this.forceUpdate()
            }
            else {
                alert("Hata")
            }

        }).catch((error) => {
            setTimeout(() => {
                this.setState({ requesting: false })
            }, 1000)
            console.log(error)
        })
    }


    render() {

        return (
            <div style={{ width: "95%", display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", overflowY: "hidden" }}>

                {this.state.requesting &&
                    <Loading />
                }



                <div style={{ position: "absolute", zIndex: 2, top: "82px", right: "0", left: "82px", display: "flex", flexDirection: "row", border: "solid 2px black", borderRadius: "8px 0 0 8px", overflowX: "auto", background: "black", marginBottom: "20px" }} >
                    {this.props.data.data.map((item, index) =>
                        <div key={index} onClick={() => { this.setState({ selectedCategory: index }) }} style={{ whiteSpace: "nowrap", padding: "10px 8px", color: this.state.selectedCategory === index ? "black" : "white", background: this.state.selectedCategory === index ? "#ddd" : "black", borderRight: "solid 1px #888" }}>{item.category}</div>
                    )}
                </div>

                {this.state.addItemVisible ?
                    <div style={{ width: "80%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", paddingTop: "32px" }}>

                        <p style={{ display: "flex", alignItems: "center", marginTop: "16px", fontFamily: "Montserrat", fontWeight: 500, width: "100%", marginBottom: "10px" }}>Ürün Adı: {!this.state.item_name && <span style={{ marginLeft: "8px", color: "red", fontWeight: "bold" }}>*</span>}</p>
                        <input onChange={e => this.setState({ item_name: e.target.value })} value={this.state.item_name} style={{ width: "100%" }} />


                        <p style={{ display: "flex", alignItems: "center", marginTop: "16px", fontFamily: "Montserrat", fontWeight: 500, width: "100%", marginBottom: "10px" }}>Ürün Açıklaması:  {!this.state.item_description && <span style={{ marginLeft: "8px", color: "red", fontWeight: "bold" }}>*</span>}</p>
                        <input onChange={e => this.setState({ item_description: e.target.value })} value={this.state.item_description} style={{ width: "100%" }} />


                        <p style={{ display: "flex", alignItems: "center", marginTop: "16px", fontFamily: "Montserrat", fontWeight: 500, width: "100%", marginBottom: "10px" }}>Ürün Fiyatı: {!this.state.item_price && <span style={{ marginLeft: "8px", color: "red", fontWeight: "bold" }}>*</span>} </p>
                        <input onChange={
                            e => {
                                let temp = e.target.value.replace(/[^0-9\.]/g, '')
                                let shaped = ""
                                let includes = false
                                for (let i = 0; i < temp.length; i++) {
                                    if (!includes || temp[i] !== ".") shaped += temp[i]
                                    if (temp[i] === ".") includes = true
                                }
                                this.setState({ item_price: shaped })
                            }
                        } value={this.state.item_price} style={{ width: "100%" }} />


                        <p style={{ marginTop: "24px", fontFamily: "Montserrat", fontWeight: 500, width: "100%" }}>Ürün Fotoğrafı: {!this.state.item_photo && <span style={{ marginLeft: "8px", color: "red", fontWeight: "bold" }}>*</span>}</p>
                        {this.state.item_photo ?
                            <img alt="item_photo" src={"https://www.menulutfen.com/images/item/" + this.state.item_photo} style={{ marginTop: "10px", objectFit: "cover", width: "200px", height: "200px", borderRadius: "32px" }} />
                            :
                            <img alt="item_photo" src={require("../assets/restaurant-menu.png")} style={{ marginTop: "10px", objectFit: "cover", width: "200px", height: "200px", borderRadius: "32px" }} />
                        }
                        <div style={{paddingBottom: "12px", borderBottom: "solid 1px #ddd", marginTop: "12px", width: "100%", display: "flex", justifyContent: 'center', alignItems: "center", }}>
                            <label style={{padding:"4px 8px", border:"solid 2px #08f", color:"#08f", borderRadius:"8px"}} for="item_upload">Fotoğraf seçin</label>
                            <input id="item_upload" type="file" accept="image/*" onChange={this.itemUpload} title={" "} style={{ display: "none" }} />
                        </div>
                        {this.state.editItemData ?
                            <button onClick={this.edit} disabled={this.state.requesting} className="btn btn-primary" style={{ marginTop: "12px", fontFamily: "Montserrat", fontWeight: 500 }}>Kaydet</button>
                            :
                            <button onClick={this.add} disabled={this.state.requesting} className="btn btn-success" style={{ marginTop: "12px", fontFamily: "Montserrat", fontWeight: 500 }}>Ekle</button>}

                    </div>
                    :
                    <div>
                        {this.props.data.data.length ?
                            <div style={{ overflowY: "auto", position: "absolute", right: "10px", left: "82px", top: "128px", bottom: "0px", paddingTop: "12px" }}>
                                <Items editItem={this.editItem} removeItem={this.removeItem} chevron={false} data={this.props.data.data[this.state.selectedCategory].items} />
                            </div>
                            :
                            <div style={{ overflowY: "auto", position: "absolute", right: "10px", left: "82px", top: "128px", bottom: "0px", paddingTop: "12px" }}>
                                Ekli ürün bulunmamakta.
                            </div>
                        }
                    </div>

                }

                <div onClick={this.addItem}
                    style={{ zIndex: 2001, position: "absolute", bottom: "10px", right: "10px", display: "flex", justifyContent: "center", alignItems: "center", color: "white", border: "solid 6px #ddd", background: this.state.addItemVisible ? "red" : "black", fontSize: "20px", lineHeight: "16px", fontWeight: "bold", width: "64px", height: "64px", borderRadius: "32px" }}
                > {this.state.addItemVisible ? "x" : "+"} </div>
            </div>
        )
    }

}

export default ItemsEdit;