import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Loading from "./Loading"

import axios from "axios"
import Cookies from "js-cookie"

//import '../styles/Item.css';


class Login extends React.Component {

    state = {
        fetchingData: false,
        username: null,
        password: null,
        userData: null,
        remember: false
    }

    componentDidMount() {
        if (Cookies.get("menulutfen-login")) {
            const credentials = JSON.parse(Cookies.get("menulutfen-login"))
            this.setState({ username: credentials.username, password: credentials.password, remember: true }, () => { })
        }
    }

    login = () => {
        this.setState({ fetchingData: true })
        axios.post('https://www.menulutfen.com/services/login.php',
            {
                username: this.state.username,
                password: this.state.password
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).then((response) => {

            setTimeout(() => { this.setState({ fetchingData: false }) }, 1000)


            if (response.data) {
                if (response.data.user_id && response.data.user_id.length > 0) {

                    if (this.state.remember)
                        Cookies.set("menulutfen-login", JSON.stringify({ username: this.state.username, password: this.state.password }))
                    else
                        Cookies.remove("menulutfen-login")

                    this.setState({
                        data: this.fetchData(this.shapeLoginResponse(response.data))
                    })
                }
                else {
                    alert("Kullanıcı adı veya parola yanlış.")
                }
            }

        }).catch(e => {

            setTimeout(() => { this.setState({ fetchingData: false }) }, 4000)

            console.log(e)

        })
    }

    shapeLoginResponse = data => {
        return {
            user_id: data.user_id[0],
            company_url: data.company_url[0],
            company_name: data.company_name[0],
            company_logo: data.company_logo[0],
            company_cover_photo: data.company_cover_photo[0],
            company_id: data.company_id[0],
            company_color: data.company_color[0],
        }
    }



    fetchData = (companyData) => {
        axios.post('https://www.menulutfen.com/services/fetch_data.php',
            {
                company_url: companyData.company_url
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).then((response) => {
            if (!response.data.company_url) {
                this.setState({ data: { data: [], company: companyData } })
                this.props.setData({ data: [], company: companyData })
            }
            else
                this.setState({ data: this.shapeResponse(response.data, companyData) })

        }).catch(e => {

            console.log(e)

        })
    }



    shapeResponse = (data, companyData) => {
        let temp = []

        if (data.category_language.length) {

            for (let i = 0; i < data.category_language.length; i++) {
                temp.push({
                    category: data.category_name[i],
                    category_photo: data.category_photo[i],
                    category_id: data.category_id[i],
                    items: []
                })
            }

            let shapedData = { categories: {} }
            temp.map(item => {
                if (!shapedData.categories[item.category]) {
                    shapedData.categories[item.category] = item
                }
            })

            let items = []
            for (let i = 0; i < data.category_language.length; i++) {
                items.push({
                    item_id: data.item_id[i],
                    category_id: data.category_id[i],
                    item_name: data.item_name[i],
                    item_description: data.item_description[i],
                    item_photo: data.item_photo[i],
                    item_price: data.item_price[i],
                    item_currency: data.item_currency[i],
                    item_category: data.category_name[i],
                })
            }


            items.map(item => {
                shapedData.categories[item.item_category].items.push(item)
            })

            let shapedArray = []
            Object.keys(shapedData.categories).forEach(item => {
                shapedArray.push(shapedData.categories[item])
            })


            const totalData = { data: shapedArray, company: companyData }
            
            this.setState({ fetchingData: false })

            this.props.setData(totalData)


        }
        else {
            this.setState({ data: { data: [], company: companyData } })
            this.props.setData({ data: [], company: companyData })
        }

    }




    render() {

        return (
            <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", height: "calc(100vh)", background: "black" }}>

                {this.state.fetchingData &&
                    <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000 }}><Loading /></div>
                }


                <div style={{ display: "flex", width: "80%", margin: "auto", justifyContent: "space-evenly", flexDirection: "column", height: "100%", }}>

                    <div style={{ paddingTop: "40px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                        <h1 style={{ color: "white", textAlign: "center", marginBottom: "12px" }}>𝔐𝔢𝔫ü 𝔏ü𝔱𝔣𝔢𝔫</h1>
                        <img alt="menu" src={require("../assets/restaurant-menu.png")} style={{ width: "60%", height: "auto", margin: "auto" }} />
                    </div>

                    <div style={{ paddingBottom: "40px" }}>

                        <div style={{ display: "flex", flex: 1, border: "solid 2px white", padding: "4px 8px 4px 16px", borderRadius: "8px", overflow: "hidden", marginBottom: "10px" }}>
                            <div style={{ margin: "-4px 0 -6px -16px", background: "white" }}>
                                <img alt="user" src={require("../assets/user.png")} style={{ width: "20px", height: "20px", margin: "8px 12px 8px 12px", background: "white" }} />
                            </div>
                            <input value={this.state.username} onChange={e => { this.setState({ username: e.target.value }) }} placeholder="Kullanıcı Adı" style={{ color: "white", marginLeft: "8px", fontSize: "14px", border: "none", width: "100%", background: "transparent", fontWeight: "500" }} />
                        </div>

                        <div style={{ display: "flex", flex: 1, border: "solid 2px white", padding: "4px 8px 4px 16px", borderRadius: "8px", overflow: "hidden" }}>
                            <div style={{ margin: "-4px 0 -6px -16px", background: "white" }}>
                                <img alt="password" src={require("../assets/padlock.png")} style={{ width: "20px", height: "20px", margin: "8px 12px 8px 12px", background: "white" }} />
                            </div>
                            <input value={this.state.password} onChange={e => { this.setState({ password: e.target.value }) }} placeholder="Şifre" type={"password"} style={{ color: "white", marginLeft: "8px", fontSize: "14px", border: "none", width: "100%", background: "transparent", fontWeight: "500" }} />
                        </div>

                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", }}>
                            <button onClick={this.login} style={{ color: "black", fontFamily: "Montserrat", background: "white", padding: "8px 32px", textAlign: "center", fontWeight: "bold", letterSpacing: "3px", fontSize: "18px", borderRadius: "12px", border: "solid 4px #bbb" }}>Giriş</button>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>

                            <div>
                                <input onChange={e => { console.log(e); this.setState({ remember: e.target.checked }) }} checked={this.state.remember} type="checkbox" id="remember" name="password_remember" style={{ scale: "1.4", marginRight: "6px" }} />
                                <label htmlFor="password_remember" style={{ color: "white", fontWeight: "bold" }}>Hatırla</label>
                            </div>

                            <div>
                                <label style={{ color: "white", textDecoration: "underline", fontWeight: "bold" }}>Şifremi unuttum?</label>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        )
    }

}

export default Login;