import React from "react"
//import '../styles/Item.css';


class Landing extends React.Component {
    

    render() {

        return (
            <div dangerouslySetInnerHTML={this.createMarkup()} ></div>
        )
    }

    createMarkup() {
        return {__html: `<!DOCTYPE html>
<html
  class="js sizes customelements history pointerevents postmessage webgl websockets cssanimations csscolumns csscolumns-width csscolumns-span csscolumns-fill csscolumns-gap csscolumns-rule csscolumns-rulecolor csscolumns-rulestyle csscolumns-rulewidth csscolumns-breakbefore csscolumns-breakafter csscolumns-breakinside flexbox picture srcset webworkers"
  lang="zxx">

<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">

  <meta http-equiv="x-ua-compatible" content="ie=edge">
  <title>MenuLutfen | QR Kod ile Çalışan Dijital Menu</title>
  <meta name="description" content="dijital menü qrmenü qr menü karekod menü kare kod menü">
  <meta name="viewport" content="width=device-width, initial-scale=1">

  <link rel="icon" href="https://menulutfen.com/img/fav.ico" type="image/x-icon">

  <!-- CSS here -->
  <link rel="stylesheet" href="./src/bootstrap.min.css">
  <link rel="stylesheet" href="./src/owl.carousel.min.css">
  <link rel="stylesheet" href="./src/magnific-popup.css">
  <link rel="stylesheet" href="./src/font-awesome.min.css">
  <link rel="stylesheet" href="./src/themify-icons.css">
  <link rel="stylesheet" href="./src/nice-select.css">
  <link rel="stylesheet" href="./src/flaticon.css">
  <link rel="stylesheet" href="./src/gijgo.css">
  <link rel="stylesheet" href="./src/animate.min.css">
  <link rel="stylesheet" href="./src/slick.css">
  <link rel="stylesheet" href="./src/slicknav.css">
  <link rel="stylesheet" href="./src/style.css">
</head>

<body>
  <header>
    <div class="header-area ">
      <div id="sticky-header" class="main-header-area">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-xl-3 col-lg-2">
              <div class="logo">
                <a href="https://menulutfen.com/index.html">
                  <img src="./src/logo3.png" alt="" width="300">
                </a>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </header>
  <!-- header-end -->

  <!-- slider_area_start -->
  <div class="slider_area">
    <div class="single_slider  d-flex align-items-center slider_bg_1">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-7 col-md-6">
            <div class="slider_text" style="padding-top:50px;">
              <h3 class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s"
                style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: none;">Göz
                Alıcı Menüler İçin
                <br>
                Bir Kare Kod Yeter.</h3>
              <p class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s"
                style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s;">menulutfen.com, restoran,
                cafe, pastane, oteller, güzellik salonları ve her türlü işiniz için kare kod ile çalışan yeni nesil
                dijital menü sistemi platformudur.</p>
              <p class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s"
                style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s;">Siz de kendinize ait kare
                kodu hemen oluşturabilir, yönetim panelinden ürünlerinizi, hizmetlerinizi kategorilere ayırıp tanımlayabilirsiniz. 
                Açıklama ve resimlerle menünüzü zenginleştirebilirsiniz. <br> Oluşturduğunuz kare kodu,
                ister kartvizitinizde, ister broşürünüzde, ister amerikan servisinizde, ister masanızda, ister
                afişinizde kullanın. <br>İyi bir sunum için bir kare kod yeter.</p>
                <!--
              <p class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s"
                style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s;">Beklemeyin. Ücretsiz
                seçeneği ile dijital menünüzü oluşturmak için formu doldurun kullanmaya başlayın. </p>
              <div class="video_service_btn wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s"
                style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s;">
                <a href="https://menulutfen.com/#kayit" class="boxed-btn3">Ücretsiz Kullanmaya Başlayın</a>
              </div>
              -->
            </div>
          </div>
          <div class="col-xl-5 col-md-6">
            <div class="phone_thumb wow fadeInDown" data-wow-duration="1.1s" data-wow-delay=".2s"
              style="visibility: visible; animation-duration: 1.1s; animation-delay: 0.2s;">
              <img src="./src/phone.png" alt="">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- slider_area_end -->

  <!-- service_area_2  -->
  <div class="service_area_2">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="section_title text-center  wow fadeInUp" data-wow-duration=".5s" data-wow-delay=".3s"
            style="visibility: visible; margin-top:20px; animation-duration: 0.5s; animation-delay: 0.3s;">
            <h3>Neden Dijital Menü?</h3>
          </div>
        </div>
      </div>
      <div class="service_content_wrap">
        <div class="row">
          <div class="col-lg-4 col-md-4">
            <div class="single_service  wow fadeInUp" data-wow-duration=".5s" data-wow-delay=".3s"
              style="visibility: visible; animation-duration: 0.5s; animation-delay: 0.3s;">
              <span><i class="fas fa-hand-sparkles"></i></span>
              <h3>Önce Sağlık</h3>
              <p>Yaşadığımız salgın dönemi, hijyenin önemini bir kez daha ortaya koydu. Siz de menülerinizi
                dijitalleştirerek müşterilerinizin ve çalışanlarınızın sağlığını korumuş olursunuz.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="single_service  wow fadeInUp" data-wow-duration=".6s" data-wow-delay=".4s"
              style="visibility: visible; animation-duration: 0.6s; animation-delay: 0.4s;">
              <span><i class="far fa-eye"></i></span>
              <h3>Zengin İçerik, Etkileyici Sunum</h3>
              <p>Menülerinizi ürün grupları altında oluşturup, fotoğraf ve içerikler ile zenginleştirebilir, hatta
                ürünlerin içeriklerine alerjen uyarılarını da yaza bilirsiniz.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4  wow fadeInUp" data-wow-duration=".7s" data-wow-delay=".5s"
            style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.5s;">
            <div class="single_service">
              <span><i class="fas fa-money-bill-alt"></i></span>
              <h3>Menü Baskı Maliyetlerine Son</h3>
              <p>Artık menü baskısından kurtulacaksınız. Baskıdan sonra eksik fazla ürünlere son. Dilediğiniz ürünü
                ekleyip çıkarabilecek, fiyatlarını anlık olarak müdahale edebileceksiniz.</p>
            </div>
          </div>
          <!--
          <div class="col-lg-3 col-md-3  wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".6s"
            style="visibility: visible; animation-duration: 0.8s; animation-delay: 0.6s;">
            <div class="single_service">
              <span><i class="fas fa-globe"></i></span>
              <h3>Yabancı Dil Desteği</h3>
              <p>Menüleriniz artık Türkçe olmak zorunda değil. Dilerseniz İngilizce, Arapça dil seçeneği ile
                menülerinizi tercüme edip müşterilerinize çoklu dil desteği sunana bilirsiniz.</p>
            </div>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
  <!--/ service_area_2  -->


  <!-- service_area_2  -->
  <div class="service_area_2">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="section_title text-center  wow fadeInUp" data-wow-duration=".5s" data-wow-delay=".3s"
            style="visibility: visible; animation-duration: 0.5s; animation-delay: 0.3s; animation-name: none;">
            <h3>Dijital Menü Kullanmanın Sonuçları</h3>
          </div>
        </div>
      </div>
      <div class="service_content_wrap">
        <div class="row">
          <div class="col-lg-4 col-md-4">
            <div class="single_service  wow fadeInUp" data-wow-duration=".5s" data-wow-delay=".3s"
              style="visibility: visible; animation-duration: 0.5s; animation-delay: 0.3s; animation-name: none;">
              <span><i class="fas fa-chart-line"></i></span>
              <h3>Ciro Artışı</h3>
              <p>İşletmenizi ziyaret eden müşteriler, servis başlangıcında menü alırlar. Daha sonra menü talep etmezler.
                Ancak karekod sayesinde menünüzü defalarca okutup inceleyebilir ve menünüzdeki ürünlerden daha çok
                sipariş verirler. Bu durum bir çok işletme tarafından doğrulanmıştır.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="single_service  wow fadeInUp" data-wow-duration=".6s" data-wow-delay=".4s"
              style="visibility: visible; animation-duration: 0.6s; animation-delay: 0.4s; animation-name: none;">
              <span><i class="fab fa-google"></i></span>
              <h3>Arama Motorlarında Üst Sıralara Çıkma</h3>
              <p>İşletmenize ait web sayfalarının günlük ziyaretçi sayıları sınırlıdır.Oysa ağırladığınız her müşteri
                dijital menünüzü defalarca incelerler. Sonuçta her inceleme, internet üzerinde işletme adınızın
                defalarca tıklanması anlamına gelir ve işletmenizin arama motorlarında birden üst sıralara çıkmanıza
                neden olur.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4  wow fadeInUp" data-wow-duration=".7s" data-wow-delay=".5s"
            style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.5s; animation-name: none;">
            <div class="single_service">
              <span><i class="far fa-laugh-wink"></i></span>
              <h3>Menülerinizi Güncellemek Hiç Bu kadar kolay Olmamıştı</h3>
              <p>Menülerinize ürün eklemek çıkarmak, fiyatları güncellemek hiç bu kadar kolay olmamıştı. Dilerseniz
                biten ürünlerinizi anında menüden kaldıra bilirsiniz. Dilerseniz mevsimine göre ürün ekleyip çıkarta
                bilirsiniz. Hiçbir baskı, tasarım maliyeti olmadan. Kontrol tamamen sizde.</p>
            </div>
          </div>
          <!--
          <div class="col-lg-3 col-md-3  wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".6s"
            style="visibility: visible; animation-duration: 0.8s; animation-delay: 0.6s; animation-name: none;">
            <div class="single_service">
              <span><i class="fas fa-globe-africa"></i></span>
              <h3>Artık Tüm Konuklarınız Sizi Anlıyor</h3>
              <p>Dijital menüde çok az zahmetle birden fazla dil kullana bilirsiniz. Böylece İngilizce bilen veya Arapça
                bilen bir personel bulundurmak zorunda kalmazsınız. Herkezle anlaşırsınız.</p>
            </div>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
  <!--/ service_area_2  -->


  <!-- about  -->
  <div class="features_area ">
    <div class="container">
      <div class="features_main_wrap">
        <div class="row  align-items-center">
          <div class="col-xl-5 col-lg-5 col-md-6">
            <div class="features_info2">
              <h3>Yeni Normal <br> Yeni Hayat</h3>
              <p>Yaşadığımız salgın dönemi bize birçok şey öğretti. Artık buna yeni normal diyorlar.</p>
              <p>İşletmelerimizde bir çok önlem aldığımız gibi, menülerimizi dijitalleştirmek bu önlemlerin küçük bir
                örneği. Dijital menü, hem sağlıklı, hem akıllı, hem gösterişli, hem çok kullanışlı. Tek menüde
                fotoğraflar, içerikler, alerjen uyarıları, ekleme, çıkarma, fiyat güncelleme, yabancı dil bunlardan
                birkaçı.</p>
              <p>Siz de menulutfen.com ile dijital menüye geçin, faydalarını yaşadıkça görün.</p>
            </div>
          </div>
          <div class="col-xl-5 col-lg-5 offset-xl-1 offset-lg-1 col-md-6 ">
            <div class="about_draw wow fadeInUp" data-wow-duration=".7s" data-wow-delay=".5s"
              style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.5s; animation-name: none;">
              <img src="./src/draw.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="features_main_wrap">
        <div class="row  align-items-center">
          <div class="col-xl-5 col-lg-5 offset-xl-1 offset-lg-1 col-md-6">
            <div class="about_image wow fadeInLeft" data-wow-duration=".4s" data-wow-delay=".3s"
              style="visibility: visible; animation-duration: 0.4s; animation-delay: 0.3s; animation-name: none;">
              <img src="./src/phone(1).png" alt="">
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6">
            <div class="features_info">
              <h3 class="wow fadeInUp" data-wow-duration=".5s" data-wow-delay=".3s"
                style="visibility: visible; animation-duration: 0.5s; animation-delay: 0.3s; animation-name: none;">
                menulutfen.com İle Dijital Menü Oluşturmak Çok Kolay</h3>
              <br><br>
              <ul>
                
                <li class="wow fadeInUp" data-wow-duration=".7s" data-wow-delay=".5s"
                  style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.5s; animation-name: none;">
                  Epostanıza gelen kullanıcı adı ve şifre ile yönetim paneline girin.</li>
                <li class="wow fadeInUp" data-wow-duration=".9s" data-wow-delay=".7s"
                  style="visibility: visible; animation-duration: 0.9s; animation-delay: 0.7s; animation-name: none;">
                  Ana sayfada menü kare kodunuz hazırdır. Düzenleme bölümünden uygun ölçülerde logo ve kapak resmini
                  yükleyin. Tema rengini ve temayı seçin.</li>
                <li class="wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".6s"
                  style="visibility: visible; animation-duration: 0.8s; animation-delay: 0.6s; animation-name: none;">
                  Ürünler menüsünden ürünleri ekleyin. </li>
                <li class="wow fadeInUp" data-wow-duration=".9s" data-wow-delay=".7s"
                  style="visibility: visible; animation-duration: 0.9s; animation-delay: 0.7s; animation-name: none;">
                  Değişiklikleri görmek için menüyü yayınlayın ve kare kodu okutun. Menü karşınızda.</li>
                <li class="wow fadeInUp" data-wow-duration=".9s" data-wow-delay=".7s"
                  style="visibility: visible; animation-duration: 0.9s; animation-delay: 0.7s; animation-name: none;">
                  Menü bilgisi sayfasında bulunan kare kodu bastırıp masalarınıza koyun. İşte bu kadar.</li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--/ about  -->

  <link rel="stylesheet" href="./src/all.min.css"
    integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ=="
    crossorigin="anonymous" referrerpolicy="no-referrer">






  <section class="pricing py-5">
    <div class="container">
<!--
      <h3 style="
    text-align: center;
    color: #fff;
    font-size: 41px;
    margin-bottom: 30px;
    margin-top: -10px;
">Size En Uygun Olanı Seçin</h3>
-->

      <div class="row justify-content-md-center">
        <!-- Free Tier -->
        <!--
        <div class="col-sm-6 col-md-6 col-lg-3 d-none">
          <div class="card mb-5 mb-lg-0">
            <div class="card-body">
              <h5 class="card-title text-muted text-uppercase text-center">ÜCRETSİZ</h5>
              <h6 class="card-price text-center">₺0<span class="period">/1 YIL</span></h6>
              <hr>
              <ul class="fa-ul">



                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Sınırsız Grup</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Sınırsız Ürün</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Telefon Desteği</li>

                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Resimsiz Tema</li>
                <li class="text-muted"><span class="fa-li"><i class="fas fa-times"></i></span>• Grup Resimli Tema</li>
                <li class="text-muted"><span class="fa-li"><i class="fas fa-times"></i></span>• Tümü Resimli Tema</li>
                <li class="text-muted"><span class="fa-li"><i class="fas fa-times"></i></span>• Kapak Resmi</li>
                <li class="text-muted"><span class="fa-li"><i class="fas fa-times"></i></span>• Logo</li>
                <li class="text-muted"><span class="fa-li"><i class="fas fa-times"></i></span>• Menü Girişi Desteği</li>
                <li class="text-muted"><span class="fa-li"><i class="fas fa-times"></i></span>• 1.Yabancı Dil</li>
                <li class="text-muted"><span class="fa-li"><i class="fas fa-times"></i></span>• 2.Yabancı Dil</li>


              </ul>
              <div class="d-grid d-none">
                <a href="https://menulutfen.com/#" class="btn btn-primary text-uppercase">Satın Al</a>
              </div>
            </div>
          </div>
        </div>

        -->



        <!-- Free Tier -->
        <div class="col-sm-3 col-md-3 col-lg-3" style="min-width: 300px;">
          <div class="card mb-3 mb-lg-0">
            <div class="card-body">
              <h6 class="card-price text-center">₺800<span class="period">/YIL</span></h6>
              <hr>
              <ul class="fa-ul">
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Sınırsız Grup</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Sınırsız Ürün</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Telefon Desteği</li>

                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Resimsiz Tema</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Grup Resimli Tema</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Tümü Resimli Tema</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Kapak Resmi </li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Logo </li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Menü Girişi Desteği</li>


              </ul>
              <div class="d-grid d-none">
                <a href="https://menulutfen.com/#" class="btn btn-primary text-uppercase">Satın Al</a>
              </div>
            </div>
          </div>
        </div>
        <!-- 
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="card mb-5 mb-lg-0">
            <div class="card-body">
              <h5 class="card-title text-muted text-uppercase text-center">PAKET 2</h5>
              <h6 class="card-price text-center">₺2,000<span class="period">/YIL</span></h6>
              <hr>
              <ul class="fa-ul">
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Sınırsız Grup</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Sınırsız Ürün</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Telefon Desteği</li>

                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Resimsiz Tema</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Grup Resimli Tema</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Tümü Resimli Tema</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Kapak Resmi </li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Logo </li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Menü Girişi Desteği</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• 1.Yabancı Dil</li>
                <li class="text-muted"><span class="fa-li"><i class="fas fa-times"></i></span>• 2.Yabancı Dil</li>


              </ul>
              <div class="d-grid d-none">
                <a href="https://menulutfen.com/#" class="btn btn-primary text-uppercase">Satın Al</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="card mb-5 mb-lg-0">
            <div class="card-body">
              <h5 class="card-title text-muted text-uppercase text-center">PAKET 3</h5>
              <h6 class="card-price text-center">₺2,500<span class="period">/YIL</span></h6>
              <hr>
              <ul class="fa-ul">
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Sınırsız Grup</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Sınırsız Ürün</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Telefon Desteği</li>

                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Resimsiz Tema</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Grup Resimli Tema</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Tümü Resimli Tema</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Kapak Resmi </li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Logo </li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• Menü Girişi Desteği</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• 1.Yabancı Dil</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>• 2.Yabancı Dil</li>



              </ul>
              <div class="d-grid d-none">
                <a href="https://menulutfen.com/#" class="btn btn-primary text-uppercase">Satın Al</a>
              </div>
            </div>
          </div>
        </div>

      -->

      </div>
    </div>
  </section>

  <style>
    section.pricing {
      background: #007bff;
      background: linear-gradient(to right, #f5b408, #fecf53);
    }

    .pricing .card {
      border: none;
      border-radius: 1rem;
      transition: all 0.2s;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    }

    .pricing hr {
      margin: 1.5rem 0;
    }

    .pricing .card-title {
      margin: 0.5rem 0;
      font-size: 0.9rem;
      letter-spacing: .1rem;
      font-weight: bold;
    }

    .pricing .card-price {
      font-size: 3rem;
      margin: 0;
    }

    .pricing .card-price .period {
      font-size: 0.8rem;
    }

    .pricing ul li {
      margin-bottom: 1rem;
    }

    .pricing .text-muted {
      opacity: 0.7;
    }

    .pricing .btn {
      font-size: 80%;
      border-radius: 5rem;
      letter-spacing: .1rem;
      font-weight: bold;
      padding: 1rem;
      opacity: 0.7;
      transition: all 0.2s;
    }

    /* Hover Effects on Card */

    @media (min-width: 992px) {
      .pricing .card:hover {
        margin-top: -.25rem;
        margin-bottom: .25rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
      }

      .pricing .card:hover .btn {
        opacity: 1;
      }
    }
  </style>



  <style>
    .rform {
      background-color: #ffd25d;
      border-radius: 0px;
      background-image: url(sitev2/img/regbg2.png);
      background-position: center;
      "

    }

    .rform .card {
      border: none;
      border-radius: 1rem;
      transition: all 0.2s;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    }
  </style>

<!--
  <section class="rform py-5" style="padding-top:100px !important;" id="kayit">
    <div class="container mt-3">

      <div class="features_main_wrap">
        <div class="row  align-items-center">
          <div class="col-xl-12 col-lg-12 col-md-12">



            <div class="row" style="max-width:520px; margin:0 auto;">
              <div class="col-sm-12 col-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title mt-2"><i class="fa fa-pen fa-fw"></i> Hemen Kayıt Olun</h3><small><i
                        class="far fa-question-circle"></i> Kayıt olduktan sonra hemen menünüzü oluşturun ve kullanmaya
                      başlayın.</small>
                  </div>
                                            <form action="https://menulutfen.com/admin/?islemsite/sitedemenukayit" method="post" name="uye" onsubmit="return formkontrol()">
                                            <div class="card-body">

                                                <div class="form-group">
                                                    <label for="exampleInputName1">Menü İsmi</label>
                                                    <input type="text" class="form-control" id="menuismi" name="menuismi" onfocus="rclas(this)">
                                                    <div class="invalid-tooltip">
                                                        Boş Geçmeyiniz.
                                                    </div>
                                                    <small id="Small1" class="form-text text-muted"><i class="far fa-question-circle"></i> Başlıkta gözükecek olan firma ve şube adınız. Ör: Çiçek Kafe - FSM</small>
                                                </div>
                                                
                                                <div class="form-group">
                                                    <label for="exampleInputName1">Ad Soyad</label>
                                                    <input type="text" class="form-control" id="adsoyad" name="adsoyad" value="" placeholder="" onfocus="rclas(this)">
                                                    <div class="invalid-tooltip">
                                                        Boş Geçmeyiniz.
                                                    </div>  
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputName1">ePosta Adresiniz</label>
                                                    <input type="email" class="form-control" id="eposta" name="eposta" value="" placeholder="" onfocus="rclas(this)">
                                                    <small id="Small1" class="form-text text-muted"><i class="far fa-question-circle"></i> Menü bilgileri ve yönetim paneli bilgileri girilen eposta adresine gönderilecektir. Lütfen doğru girdiğinize emin olunuz.</small>
                                                    <div class="invalid-tooltip">
                                                    Boş Geçmeyiniz.
                                                    </div>  
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputName1">Telefon Numaranız</label>
                                                    <input type="text" class="form-control" id="telno" name="telno" value="" placeholder="" onfocus="rclas(this)" data-inputmask="&#39;mask&#39;: &#39;0599 999 99 99&#39;">
                                                    <small id="Small1" class="form-text text-muted"><i class="far fa-question-circle"></i> Cep telfonunuzu giriniz. 05*********</small>
                                                    <div class="invalid-tooltip">
                                                    Boş Geçmeyiniz.
                                                    </div>  
                                                </div>

                                                <div class="form-group">
                                                    <label for="exampleInputName1">İşletmedeki Göreviniz</label>
                                                    <select class="form-control" id="gorev" name="gorev">
                                                      <option value="" sellected="">Lütfen Seçiniz</option>
                                                      <option value="Sahibi">Sahibi</option>
                                                      <option value="Müdür">Müdür</option>
                                                      <option value="Çalışan">Çalışan</option>
                                                      <option value="Diğer">Diğer</option>
                                                    </select>
                                                    <small id="Small1" class="form-text text-muted d-none"><i class="far fa-question-circle"></i></small>
                                                    <div class="invalid-tooltip">
                                                    Boş Geçmeyiniz.
                                                    </div>  
                                                </div>

                                                <div class="form-group">
                                                  <div class="custom-control custom-checkbox">
                                                      <input type="checkbox" class="custom-control-input" id="customCheck1" name="sozlesme" value="OK">
                                                      <label class="custom-control-label" for="customCheck1"><a href="javascript:void(0)" data-toggle="modal" data-target="#modalsoz">Kullanım koşullarını</a> okudum ve kabul ediyorum.</label>
                                                  </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputName1">Güvenlik Kodu</label>
                                                    <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" style="padding:0px;background-color: #eeeeec;"><img id="imgCaptcha" src="./src/captcha.bmp"></span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-label="" name="securekod" placeholder="Güvenlik Kodu" id="securekod" data-inputmask="&#39;mask&#39;: &#39;9999&#39;" onfocus="rclas(this)" inputmode="text">
                                                    <div class="input-group-append">
                                                        <button type="button" class="frm input-group-text" onclick="RefreshImage(&#39;imgCaptcha&#39;)"><i class="fa fa-sync fa-fw"></i></button>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-0" style="text-align:right;">
                                                    <button type="submit" class="btn btn-primary btn-shadow btn-br" style="min-width:10rem;" data-toggle="modal" data-target="#premodal"><i class="mdi mdi-check-circle-outline"></i>Gönder</button>
                                                </div>
                                            </div>
                                        </form>

                </div>
              </div>
            </div>



          </div>
          <div class="col-xl-5 col-lg-5 offset-xl-1 offset-lg-1 col-md-6 d-none">
            <div class="about_draw wow fadeInUp animated animated" data-wow-duration=".7s" data-wow-delay=".5s"
              style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.5s;">
              <img src="./src/draw.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
-->
  <!-- prising_area  -->
  <div class="prising_area">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="section_title text-center wow fadeInUp" data-wow-duration=".5s" data-wow-delay=".5s"
            style="visibility: visible; animation-duration: 0.5s; animation-delay: 0.5s; animation-name: none;">
            <h3>Bize Hemen Ulaşın</h3>
            <p>Dijital menüyle ilgili her türlü sorunuz için<br><a href="mailto:info@menulutfen.com?subject=Bilgi"> info@menulutfen.com
              </a><br> adresine mail atabilirsiniz. </p>
            <p>Bir an önce, her şeyin yoluna girmesi dileği ile...</p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!--/ prising_area  -->

  <div class="modal fade" id="msgmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" style="z-index:9999999999">
    <div class="modal-dialog  modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">Tamam</button>
        </div>
      </div>
    </div>
  </div>



  <div class="modal fade" id="premodal" tabindex="-1" role="dialog" data-backdrop="static"
    aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:9999999999">
    <div class="modal-dialog  modal-dialog-centered" role="document">
      <div class="modal-content">

        <div class="modal-body">

          <div class="loader">Loading...</div>
        </div>

      </div>
    </div>
  </div>

  <style>
    .loader,
    .loader:before,
    .loader:after {
      background: #013665;
      -webkit-animation: load1 1s infinite ease-in-out;
      animation: load1 1s infinite ease-in-out;
      width: 1em;
      height: 4em;
    }

    .loader {
      color: #013665;
      text-indent: -9999em;
      margin: 88px auto;
      position: relative;
      font-size: 11px;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    .loader:before,
    .loader:after {
      position: absolute;
      top: 0;
      content: '';
    }

    .loader:before {
      left: -1.5em;
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .loader:after {
      left: 1.5em;
    }

    @-webkit-keyframes load1 {

      0%,
      80%,
      100% {
        box-shadow: 0 0;
        height: 4em;
      }

      40% {
        box-shadow: 0 -2em;
        height: 5em;
      }
    }

    @keyframes load1 {

      0%,
      80%,
      100% {
        box-shadow: 0 0;
        height: 4em;
      }

      40% {
        box-shadow: 0 -2em;
        height: 5em;
      }
    }
  </style>

  <div class="modal fade" id="modalsoz" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    style="z-index:9999999999">
    <div class="modal-dialog modal-lg" role="document" style="height: 90%;">
      <div class="modal-content" style="overflow: auto;height:100%">
        <div class="modal-header">
          <h3><i class="fa fa-file-alt"></i> Kullanıcı Sözleşmesi</h3>
        </div>
        <div class="modal-body" style="overflow: auto;height:100%">



          <div class="plain-text">
            <h3>Madde 1. Taraflar</h3>

            <p>İşbu Temassız Dijital QR Menü Oluşturma Sistemi Kullanıcı Sözleşmesi ("Sözleşme"),</p>

            <p>a) www.menulutfen.com internet sitesinin faaliyetlerini yürüten Menücebimde QR MENU ("Menücebimde QR
              MENU") ile</p>

            <p>b) www.menulutfen.com internet sitesine üye olan ve bu Sözleşme'yi onaylayan kullanıcı ("Şirket")</p>

            <p>arasında akdedilmiştir.</p>

            <h3>Madde 2. Sözleşmenin Konusu</h3>

            <p>İşbu Sözleşme'nin konusu, Site üzerinden erişilen Temassız Dijital QR Menü Oluşturma Sistemi'nin
              ("Platform") Şirket'e sunulmasına, Şirket'in Platform'dan faydalanmasına ve Şirket ve Şirket'in Platform'a
              erişimine izin verdiği Şirket çalışanları ("Çalışanlar") tarafından Platform'a yüklenen bilgi, belge ve
              verilere ("İçerik") ilişkin koşul ve şartlar ile tarafların hak ve yükümlülüklerinin belirlenmesidir.</p>

            <h3>Madde 3. Tarafların Hak ve Yükümlülükleri</h3>

            <p>3.1. Menücebimde QR MENU, Platform'u Şirket tarafından talep edilen paket kapsamına uygun olarak, ilgili
              paket için Menücebimde QR MENU tarafından belirlenen ücretlerin ödenmesi karşılığında Şirket'e
              sağlayacaktır. Şirket, Menücebimde QR MENU tarafından kendisine tanınan ücretsiz deneme süresinin sona
              ermesini takiben ilgili ücretleri ödememesi halinde Platform'dan faydalanamayacağını kabul eder.</p>

            <p>3.2. Şirket, Platform'a üye olurken verdiği bilgilerin tam, doğru ve güncel olduğunu, bu bilgilerde
              herhangi bir değişiklik olması halinde, söz konusu bilgileri derhal güncelleyeceğini kabul eder. Bu
              bilgilerin eksik veya gerçeğe aykırı olarak verilmesi ya da güncel olmaması nedeniyle Platform'a erişim
              sağlanamamasından veya Platform'dan faydalanılamamasından Menücebimde QR MENU sorumlu değildir.</p>

            <p>3.3. Şirket, Menücebimde QR MENU tarafından kendisine verilmiş olan şifreyi başka kişi ya da kuruluşlara
              veremez, Şirket'in söz konusu şifreyi kullanma hakkı ve şifrenin gizliliğinin ve güvenliğinin sağlanması
              yükümlülüğü bizzat kendisine aittir. Şifrenin yetkili olmayan bir kişi tarafından kullanılması, kaybolması
              veya el değiştirmesi nedeniyle kendisinin, Çalışanlar'ın ve/veya üçüncü kişilerin uğradığı zararlardan
              münhasıran sorumlu olduğunu, bu durumda meydana gelen zararlardan Menücebimde QR MENU'nın sorumlu
              olmayacağını kabul eder. Şirket, Platform'a erişimine izin verdiği Şirket görevlilerinin Platform
              üzerindeki faaliyetlerinden bizzat sorumludur.</p>

            <p>3.4. Menücebimde QR MENU, Platform'da oluşturulan veya Platform'a yüklenen bilgi ve belgelerin
              yedeklenmesine ilişkin her türlü sorumluluğun kendisine ait olduğunu, bilgi ve belgelerin herhangi bir
              zamanda ve herhangi bir nedenle Platform'dan silinmesi halinde Şirket'in herhangi bir sorumluluğu
              olmadığını kabul eder.</p>

            <p>3.5. Menücebimde QR MENU yalnızca Platform'a ilişkin altyapıyı sağlamakta olup Platform'da oluşturulan ve
              paylaşılan bilgi ve belgelere ilişkin herhangi bir sorumluluğu bulunmamaktadır. Şirket, Platform'da
              oluşturulan belgelerin veya Platform'a yüklenen bilgi ve içeriklerin doğru ve hukuka uygun olduğunu,
              belge, bilgi ve içeriklerin herhangi bir hak ihlaline sebep olmayacağını kabul ve taahhüt etmektedir.
              Platform'da oluşturulan ve paylaşılan içeriklerin ve bunlarla ilgili diğer süreçlerin sosyal güvenlik ve
              iş hukuku başta olmak üzere ilgili mevzuata uygun olmasının sağlanması, sayılanlarla sınırlı olmaksızın
              Çalışanlar'a belge gönderilmesi, bunların takibi, belgelerin çıktısının alınması ve saklanması ile
              Platform'un kullanımı ile ilişkili olarak doğabilecek her türlü sorumluluk münhasıran Şirket'e aittir.
              Platform'un işbu Sözleşme ve ilgili mevzuata uygun olmayan şekilde kullanımı nedeniyle Şirket'in
              Platform'dan faydalanma imkanı kısmen veya tamamen askıya alınabilecektir.</p>

            <p>3.6. Şirket, Platform'a kişisel verileri yüklenecek Çalışanlar bakımından 6698 sayılı Kişisel Verilerin
              Korunması Kanunu kapsamında veri sorumlusu sıfatını haiz olduğunu, kişisel verilerin Platform'a yüklenmesi
              ve veri işleyen sıfatını haiz Menücebimde QR MENU tarafından bu verilerin işlenmesi de dahil olmak üzere
              bu kapsamda Çalışanlar'dan gerekli onayların alınması, Çalışanlar'a gerekli bilgilendirmelerin yapılması
              ve ilgili mevzuat kapsamındaki yükümlülüklerin yerine getirilmesinin münhasıran kendi sorumluluğunda
              olduğunu, bu kapsamda Menücebimde QR MENU'nın maruz kalabileceği her türlü talep ve zararın kendisine rücu
              edileceğini kabul eder.</p>

            <p>3.7. Menücebimde QR MENU tarafından Site ve Platform'un iyileştirilmesi ve geliştirilmesine yönelik
              olarak ve/veya yasal mevzuat çerçevesinde Site'ye erişmek için kullanılan internet servis sağlayıcısının
              adı ve Internet Protokol (IP) adresi, Site'ye erişilen tarih ve saat, Site'de bulunulan sırada erişilen
              sayfalar ve Site'ye doğrudan bağlanılmasını sağlayan web sitesinin Internet adresi gibi birtakım bilgiler
              toplanabilir.</p>

            <p>3.8. Menücebimde QR MENU, Şirket'in bilgilerini yasal bir zorunluluk olarak istendiğinde, yasal gereklere
              uygun hareket etmek veya Menücebimde QR MENU'ya tebliğ edilen yasal işlemlere uymak ve Platform ve
              Menücebimde QR MENU'nın haklarını ve mülkiyetini korumak ve savunmak için gerekli olduğuna iyi niyetle
              kanaat getirdiği hallerde açıklayabilir.</p>

            <p>3.9. Site ve Platform'un virüs ve benzeri amaçlı yazılımlardan arındırılmış olması için mevcut imkanlar
              dahilinde tedbir alınmıştır. Bunun yanında nihai güvenliğin sağlanması için Şirket'in kendi virüs koruma
              sistemini tedarik etmesi ve gerekli korunmayı sağlaması gerekmektedir.</p>

            <p>3.10. Menücebimde QR MENU, Platform'da değişiklikler yapma, Platform'un yeni sürüm, yayın, genişletilmiş
              biçimlerini vb. çıkarma, Platform'a yeni özellikler ekleme veya mevcut özellikleri kaldırma hakkına
              sahiptir.</p>

            <p>3.11. Menücebimde QR MENU'nın Platform'un işleyişini tamamen durdurması halinde, Şirket tarafından
              Platform'a yüklenmiş olan bilgi ve içeriklerin kopyaları Menücebimde QR MENU tarafından Şirket'e teslim
              edilecektir.</p>

            <p>3.12. Şirket, Platform'dan faydalanmasına ilişkin olarak gerçekleştireceği tüm işlemlerde, işbu Sözleşme
              ve ekleri ile Menücebimde QR MENU tarafından belirlenecek sair koşullara, kanuna, dürüstlük ilkelerine
              uymayı taahhüt eder. Çalışanlar'ın da Platform'u kullanımı sırasında işbu Sözleşme ile bu maddede
              belirtilen sair kurallara uymasını sağlamak Şirket'in yükümlülüğünde olup Şirket bu kapsamda gerekli tüm
              bildirim ve bilgilendirmeleri yapmak ve gerekli onayları almakla yükümlüdür.</p>

            <p>3.13. Platform'un kullanımından doğan her türlü yasal, idari ve cezai sorumluluk Şirket'e aittir.
              Menücebimde QR MENU Şirket'in veya Çalışanlar'ın Platform üzerinde gerçekleştirdiği faaliyetler ve/veya
              işbu Sözleşme ve yasaya aykırı eylemleri neticesinde Şirket, Çalışanlar ve üçüncü kişilerin
              uğrayabilecekleri zararlardan doğrudan ve/veya dolaylı olarak hiçbir şekilde sorumlu tutulamaz. Üçüncü
              kişilerden bu kapsamda gelecek her türlü talep ile Şirket'in ve/veya Çalışanlar'ın eylemlerinden
              kaynaklanan zararlar ilk talepte ödenmek üzere Şirket'e rücu edilecektir.</p>

            <h3>Madde 4. Fikri Mülkiyet Hakları</h3>

            <p>4.1. Platform içerisindeki görsel ve tasarımlar, yazılar, logolar, grafikler de dahil olmak üzere,
              Menücebimde QR MENU'ya ait sistemler ve Platform'un tüm mali, manevi ve ticari hakları Menücebimde QR
              MENU'ya aittir. Menücebimde QR MENU, Şirket'e Platform üzerinde Sözleşme süresi ile sınırlı, münhasır
              olmayan ve devredilemez bir kullanım hakkı (basit ruhsat) vermeyi kabul etmekte olup Şirket'e tanınan bu
              sınırlı kullanım hakkı Menücebimde QR MENU'ya karşı, Menücebimde QR MENU'nın başkalarına lisans verme
              hakkı başta olmak üzere diğer tüm haklarını serbestçe kullanmasını sınırlayacak şekilde yorumlanamaz.</p>

            <p>4.2. Şirket, Platform'u yalnızca işbu Sözleşme'de belirtilen kapsamda kullanacak olup, bu hakkı ödünç
              veremez, temin ettiği lisans kapsamında Çalışanlar'a sunmak dışında kiralama gibi bedelli veya bedelsiz,
              hiçbir şekilde üçüncü şahısların (kişi, kurum, kuruluş şirket vb.) yararına sunamaz, kullandıramaz. Bu
              kapsamda Menücebimde QR MENU tarafından Platform kapsamında kullanılan bilgi, içerik ve/veya yazılımın
              kopyalanması, çoğaltılması ve/veya işbu Sözleşme kapsamında belirlenen faydalanmanın ötesinde
              kullanılması, dağıtılması, işlenmesi kesinlikle yasaktır. Ayrıca Şirket'in Platform veya Menücebimde QR
              MENU sistemlerinin güvenliğini tehdit edebilecek ve/veya Platform, Menücebimde QR MENU ve diğer
              kullanıcılara zarar verebilecek eylemlerde bulunması, Platform'un ve Platform'a ait diğer yazılımların
              çalışmasını veya diğer kullanıcıların Platform'dan faydalanmasını engelleyebilecek herhangi bir girişimde
              bulunması, bu sonuçları verecek şekilde sisteme veya Platform'a orantısız yük bindirmesi; Platform'un
              kaynak kodlarına veya Menücebimde QR MENU'nin sistemlerine yetkisiz bir şekilde erişmesi, bu bilgileri
              kopyalaması, silmesi, değiştirmesi ya da bu yönde denemeler yapması; Platform'un çalışmasını engelleyecek
              yazılımlar kullanması, kullanmaya çalışması veya her türlü yazılım, donanım ve sunucuların çalışmasını
              aksatması, bozulmasına yol açması, tersine mühendislik yapması, saldırılar düzenlemesi, meşgul etmesi veya
              bunlara sair surette müdahale etmesi, Menücebimde QR MENU sunucularına erişim sağlamaya çalışılması
              kesinlikle yasaktır. Şirket, yukarıda anılan yöntemlerle yahut sair şekillerde Menücebimde QR MENU ve
              Platform ile rekabet etmeyeceğini, bu amaçla hareket eden üçüncü taraflara destek vermeyeceğini kabul
              eder. Şirket ayrıca söz konusu yasaklara Çalışanlar'ın da uymasını sağlamakla yükümlü olup Çalışanlar'ın
              bu kapsamdaki her türlü aykırılığından müteselsilen sorumlu olacaktır.</p>

            <h3>Madde 5. Sorumlulukların Sınırlandırılması</h3>

            <p>5.1. Menücebimde QR MENU, Platform'u "Olduğu Gibi" sağlamakta olup Platform'un hatasız, kusursuz,
              kesintisiz, mükemmel olduğu veya Şirket'in kendine özel ihtiyaçlarını tamamen karşılayacağı şeklinde bir
              iddia veya taahhütte bulunmamaktadır. Menücebimde QR MENU, Şirket'in (i) Platform'u kullanması,
              kullanmaması ya da yanlış kullanması veya Sözleşme'de belirtilen taahhüt ve yükümlülüklerini yerine
              getirmemesi sebebiyle ortaya çıkacak doğrudan veya dolaylı zararlardan, her türlü kar kaybı, veri kaybı,
              iş kaybı, itibar kaybı, beklenen tasarrufun kaybı, işin kesintiye uğraması gibi her türlü maddi, manevi ve
              mali zararlardan, üçüncü bir tarafça yapılabilecek tazminat taleplerinden; (ii) tüm donanım, sistem
              yazılımı/diğer yazılımlar ve ağ ilişkili işlevden ve bu nedenle ortaya çıkacak arızalardan; (iii) iletişim
              ağı (network), internet bağlantısı, bağlantı hatalarından; (iv) Platform üzerinde Şirket, Çalışanlar veya
              üçüncü kişiler tarafından gerçekleştirilecek her türlü değişiklik, güncelleme ve benzeri çalışmalardan;
              (v) voltaj dalgalanmalarından, batarya ve elektrik kesilmelerinden, virüs bulaşmasından ve benzeri
              çevresel faktörlerden doğacak zararlardan sorumlu değildir.</p>

            <p>5.2. Menücebimde QR MENU, uygulanacak hukukun izin verdiği ölçüde, kar kaybı, şerefiye ve itibar kaybı
              dahil ancak bunlarla sınırlı olmaksızın Platform'un kullanımı neticesinde meydana gelen hiçbir dolaylı,
              özel, arızi, cezai zarardan sorumlu olmayacaktır. Şirket Platform kapsamında oluşturulan belgelerin,
              bunların içeriklerinin ve belgelerin oluşturulmasını takip eden süreçlerin ilgili mevzuata uygun
              olmasından, Şirket'in ve Çalışanlar'ın ilgili mevzuatta öngörülen yükümlülükleri yerine getirmesinden,
              Çalışanlar'ın ve üçüncü kişilerin oluşturulan belgeler ve Platform'un kullanımı ile ilişkili her türlü
              iddia ve taleplerinden münhasıran sorumlu olup bu kapsamda Menücebimde QR MENU'ya herhangi bir iddia veya
              talep yöneltemeyecektir. Menücebimde QR MENU, işbu Sözleşme kapsamında ticari elverişlilik, belli bir
              amaca veya kullanıma uygunluk veya ihlalin söz konusu olmamasına ilişkin olarak açık veya zımni herhangi
              bir taahhütte bulunmamaktadır. Menücebimde QR MENU'nın işbu Sözleşme kapsamında şirket nezdinde
              doğabilecek her türlü zarardan sorumluluğu her halükarda satın alınan paket için belirlenen aylık ücretin
              iki katı ile sınırlı olacaktır.</p>

            <p>5.3. Şirket ve Çalışanlar, Platform'da oluşturulan belgelerden ve bunların içeriklerinden, bu belgelerin
              ve Platform'un kullanımından münhasıran sorumludur. Şirket, belgeler, içerikler, hizmet, fikri mülkiyet
              ihlalleri ve Platform'un kullanımına ilişkin olarak Çalışanlar, üçüncü kişi ve kurumlar tarafından
              iletilebilecek her türlü iddia ve talepten (yargılama masrafları ve avukatlık ücretleri de dahil olmak
              üzere) Menücebimde QR MENU'yı beri kıldığını kabul eder.</p>

            <p>5.4. Şirket, Platform üzerinden sunulan içeriklere erişim ve bunların kalitesinin büyük ölçüde ilgili
              internet servis sağlayıcısından temin edilen hizmetin kalitesine dayandığını ve söz konusu hizmet
              kalitesinden kaynaklı sorunlarda Menücebimde QR MENU'nın herhangi bir sorumluluğunun bulunmadığını,
              Menücebimde QR MENU'nın Platform'un herhangi bir cihaz ya da web tarayıcı ile uyumlu olduğuna dair bir
              taahhütte bulunmadığını, Platform'un işleyişinin kusurdan ari olmadığını ve zaman zaman teknik aksaklıklar
              veya erişim engelleriyle karşılaşabileceğini bildiğini kabul ve beyan eder.</p>

            <h3>Madde 6. Sözleşmenin Feshi</h3>

            <p>6.1. İşbu Sözleşme Şirket'in üyeliğini iptal etmesi veya Menücebimde QR MENU tarafından üyeliğinin iptal
              edilmesine kadar yürürlükte kalacaktır. Taraflardan birinin Sözleşme'nin herhangi bir hükmünü yahut ilgili
              mevzuatı ihlal etmesi halleri başta olmak üzere ya da herhangi bir neden göstermeksizin diğer taraf
              Sözleşme'yi tek taraflı olarak feshedebilecektir. İşbu Sözleşme'nin herhangi bir nedenle feshi ya da sona
              ermesi halinde Şirket tarafından peşin olarak ödenmiş bedellerin iadesi söz konusu olmayacaktır.</p>

            <p>6.2. Şirket, işbu Sözleşme'nin taraflardan herhangi biri tarafından feshedilmesinden itibaren en geç 1
              (bir) ay içerisinde, Platform'a yüklediği ve/veya Platform üzerinde oluşturulan bilgi ve belge ile bunlara
              ilişkin kayıtların kendisine teslim edilmesini talep edebilir. Menücebimde QR MENU, Şirket'in belirtilen
              süre içerisinde bahsi geçen bilgi ve belge ile bunlara ilişkin kayıtların teslim edilmesi yönünde herhangi
              bir talepte bulunmaması halinde, söz konusu bilgi ve belge ile bunlara ilişkin kayıtları mevzuattan
              kaynaklanan saklama yükümlülüklerine ilişkin hükümler saklı kalmak kaydıyla, silebilir ve söz konusu
              kayıtların silinmesinden dolayı herhangi bir şekilde sorumlu değildir.</p>

            <h3>Madde 7. Muhtelif Hükümler</h3>

            <p>7.1. Mücbir sebep sayılan tüm durumlarda, taraflardan herhangi biri işbu Sözleşme ile belirlenen
              edimlerinden herhangi birini geç veya eksik ifa etme veya ifa etmeme nedeniyle sorumlu tutulamaz. Mücbir
              sebep; doğal afet, isyan, savaş, grev, lokavt, telekomünikasyon altyapısından kaynaklanan arızalar,
              elektrik kesintisi ve kötü hava koşulları da dâhil ve fakat bunlarla sınırlı olmamak kaydıyla ilgili
              tarafın makul kontrolü haricinde gerçekleşen olaylar olarak yorumlanacaktır. Mücbir sebep süresince
              tarafların edimleri askıya alınacaktır.</p>

            <p>7.2. Menücebimde QR MENU, işbu Sözleşme'nin koşullarını, Şirket'e e-posta ile bildirimde bulunmak
              kaydıyla değiştirebilir, güncelleyebilir veya iptal edebilir. Değiştirilen, güncellenen ya da yürürlükten
              kaldırılan her hüküm, Şirket'e e-posta aracılığıyla yapılan bildirim ile Şirket bakımından hüküm ifade
              edecektir. Şirket'in ilgili değişiklik, güncelleme veya iptali kabul etmemesi halinde, Şirket'in işbu
              Sözleşme'yi tek taraflı ve tazminatsız olarak fesih hakkı saklıdır.</p>

            <p>7.3. Taraflar, taraflara ait tüm bilgisayar kayıtlarının tek ve gerçek münhasır delil olarak, HMK madde
              193′e uygun şekilde esas alınacağını ve söz konusu kayıtların bir delil sözleşmesi teşkil ettiği hususunu
              kabul ve beyan eder.</p>

            <p>7.4. İşbu sözleşmeye ilişkin ihtilaflarda İzmir Mahkemeleri ve İcra Daireleri yetkilidir.</p>

            <h3>Madde 8. Yürürlük</h3>

            <p>Şirket'in, Platform'u kullanmaya başlamak için Site üzerinde sunulan formu doldurup işbu Sözleşme'yi
              onaylaması Şirket'in işbu Sözleşme'yi okuduğu ve kabul ettiği anlamına gelir. İşbu Sözleşme Şirket'in
              Platfrom'a Sözleşme'yi onaylaması ile akdedilmiş ve karşılıklı olarak yürürlüğe girmiştir.</p>
          </div>


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Tamam</button>
        </div>
      </div>
    </div>
  </div>

  <!-- JS here -->
  <script src="./src/modernizr-3.5.0.min.js"></script>
  <script src="./src/jquery-1.12.4.min.js"></script>
  <script src="./src/popper.min.js"></script>
  <script src="./src/bootstrap.min.js"></script>
  <script src="./src/owl.carousel.min.js"></script>
  <script src="./src/isotope.pkgd.min.js"></script>
  <script src="./src/ajax-form.js"></script>
  <script src="./src/waypoints.min.js"></script>
  <script src="./src/jquery.counterup.min.js"></script>
  <script src="./src/imagesloaded.pkgd.min.js"></script>
  <script src="./src/scrollIt.js"></script>
  <script src="./src/jquery.scrollUp.min.js"></script>
  <script src="./src/wow.min.js"></script>
  <script src="./src/nice-select.min.js"></script>
  <script src="./src/jquery.slicknav.min.js"></script>
  <script src="./src/jquery.magnific-popup.min.js"></script>
  <script src="./src/plugins.js"></script>
  <script src="./src/gijgo.min.js"></script>

  <!--contact js-->
  <script src="./src/contact.js"></script>
  <script src="./src/jquery.ajaxchimp.min.js"></script>
  <script src="./src/jquery.form.js"></script>
  <script src="./src/jquery.validate.min.js"></script>
  <script src="./src/mail-script.js"></script>

  <script src="./src/main.js"></script>





  <script>
    //captcha için resim yenileme foksiyonu
    function RefreshImage(valImageId) {
      var objImage = document.images[valImageId];
      if (objImage == undefined) {
        return;
      }
      var now = new Date();
      objImage.src = objImage.src.split('?')[0] + '?x=' + now.toUTCString();
    }


    function msg(baslik, mesaj, tip, modalid, focus) {


      basliktxt = '<i class="mdi mdi-alert-circle"></i>&nbsp;' + baslik;

      $('#' + modalid).on('show.bs.modal', function (event) {
        var modal = $('#' + modalid);
        modal.find('.modal-title').html(basliktxt);
        modal.find('.modal-body').html(mesaj);

      })

      $('#' + modalid).modal();

      if (focus != "") {
        $('#' + modalid).on("hidden.bs.modal", function () {

          $("input[name='" + focus + "']").focus();

        });
      }

    }


  </script>


  <style>

    i {
      display: none !important;
    }
    .checkmark__circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: #1bb049;
      fill: none;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    .checkmark {
      zoom: 1;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: block;
      stroke-width: 2;
      stroke: #fff;
      stroke-miterlimit: 10;
      /*margin: 10% auto 10%;*/
      margin: 1rem auto 2rem;
      box-shadow: inset 0px 0px 0px #fff;
      animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    }

    .checkmark__check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }

    @keyframes stroke {
      100% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes scale {

      0%,
      100% {
        transform: none;
      }

      50% {
        transform: scale3d(1.1, 1.1, 1);
      }
    }

    @keyframes fill {
      100% {
        box-shadow: inset 0px 0px 0px 50px #1bb049;
      }
    }
  </style>







  <a id="scrollUp" href="https://menulutfen.com/#top" style="display: none; position: fixed; z-index: 2147483647;"><i
      class="fa fa-angle-double-up"></i></a>
</body>

</html>`};
      }

}

export default Landing;