import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import '../styles/Category.css';


class Category extends React.Component {

    render() {

        const base_url = "https://www.menulutfen.com/images/category/"

        return (

            <div onClick={() => { this.props.pickedCategory(this.props.data.items) }} className="col-6 category-container" style={{ marginBottom: "20px" }}>

                <div style={{ width: "100%", paddingTop: "100%", position: "relative" }}>

                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>

                        <img className="photo" alt="category_photo" src={base_url + this.props.data.category_photo} style={{ background:"white", width: "100%", height: "100%", objectFit: "cover", borderRadius: "12px", overflow: "hidden" }} />

                    </div>

                    {this.props.edit &&
                        <div onClick={() => this.props.editCategory(this.props.data)} style={{ position: "absolute", top: 0, left: 0, bottom: 0, width: "50%", zIndex: 2 }}>
                            <img alt="edit" style={{ width: "20px", position: "absolute", top: "-8px", left: "-8px" }} src={require("../assets/pencil.png")} />
                        </div>
                    }
                    {this.props.edit &&
                        <div onClick={() => this.props.removeCategory(this.props.data)} style={{ position: "absolute", top: 0, right: 0, bottom: 0, width: "50%", zIndex: 2 }}>
                            <img alt="remove" style={{ width: "20px", position: "absolute", top: "-8px", right: "-8px" }} src={require("../assets/remove.png")} />
                        </div>
                    }

                    <div style={{ whiteSpace:"nowrap", textOverflow:"ellipsis", overflow:"hidden", fontWeight: "500", position: "absolute", left: "2px", right: "2px", bottom: "2px", textAlign: "center", color: "white", background: "rgba(0,0,0,.6)", borderRadius: "4px 4px 12px 12px",padding:"0 6px 3px 6px", borderTop: "solid 2px rgba(0,0,0,.2)" }}>
                        {this.props.data.category}
                    </div>

                </div>

            </div>

        )

    }

}

export default Category;